import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { createClient } from "@/prismicio";

type AppState = {
  config: {
    academyPath: string;
    adobeLaunchEndpoint?: string;
    apisPath: string;
    dashboardPath: string;
    docsPath: string;
    sesnTimeoutMs: number;
    sesnTimeoutWarnMs: number;
    prodEnv: boolean;
    goJsLicenseKey: string;
    pipelineUrl: string;
    releaseCalendarConfigured: boolean;
    idpUrl: string;
    enableEcosystem: boolean;
    searchKey: string;
    searchAppId: string;
    searchIndex: string;
    qualtricsEnvId: string;
    prismicRepo: string;
  } | null;
  isLoading: boolean;
};

const initialState: AppState = {
  config: null,
  isLoading: true,
};

export const fetchAppConfig = createAsyncThunk("app/fetchConfig", async () => {
  try {
    const config = await fetch("/console/config").then((res) => res.json());
    return config;
  } catch (err) {
    console.error(err);
  }
});

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAppConfig.fulfilled, (state, action) => {
        state.config = action.payload;
        state.isLoading = false;
      });
  },
});

export const getAppConfig = (state: RootState) => state.app.config;
export const getAppPrismicClient = createSelector(state => state.app.config?.prismicRepo, (repo) => {
  return createClient(repo ?? "")
});

export { appSlice };
