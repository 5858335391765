import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  COMPONENT_CLASS,
  DASHBOARD_ACCORDION_STATUS,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_ACCT_TYPE,
  IFX_PRODUCT_TYPE_VAL,
} from "@/utils/constants";
import { ProductType, ProductWorkflowModelType } from "..";
import { ProductStatus } from "../model/types";
import { AdditionalComponentType } from "../additional-features/AdditionalFeatureReducer";
import {
  DEPOSIT_COMPONENT_CLASS_FOR_SIDEBAR,
  LOAN_COMPONENT_CLASS_FOR_SIDEBAR,
} from "@/data/status-sidebar-menu-data";
import { deepCopy } from "@finxact/finxact-shared-ui";

export const PRODUCT_STATUS: { [key: string]: ProductStatus } = {
  not_started: "not_started",
  in_progress: "in_progress",
  completed: "completed",
};

export interface ProductSummaryFeatureFields {
  label: string;
  key: string;
  value: string;
  heading?: string;
  subFields?: ProductSummaryFeatureFields[];
  isPercentage?: boolean;
}

export interface ProductSummaryFeatureDetailSteps {
  name: string;
  link: string;
  status: ProductStatus;
  fields: ProductSummaryFeatureFields[];
  stageKey?: string;
  stageStatus?: string;
}

export interface ProductSummaryType {
  name: string;
  componentClass: string;
  featureName?: string;
  version?: number;
  status?: string;
  link: string;
  steps?: ProductSummaryFeatureDetailSteps[];
  componentName?: string;
}

const SUMMARY_FEE: ProductSummaryType = {
  name: "Fee component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["FEE_GENERAL_SETUP"],
  componentClass: COMPONENT_CLASS["fee"],
};

const SUMMARY_FEE_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Fee configuration",
    stageKey: "feeConfig",
    link: ROUTE_PATH["FEE_CONFIGURATION"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "feeConfig",
    fields: [
      {
        label: "What is the fee detail?",
        key: "feeName",
        value: "",
      },
      {
        label: "What is the fee type option?",
        key: "feeTypeOpt",
        value: "",
      },
      {
        label: "Would you like to accumulate the fee(s)?",
        key: "isAccum",
        value: "",
      },
    ],
  },
  {
    name: "Service charge details",
    stageKey: "serviceChargeDetails",
    link: ROUTE_PATH["FEE_SERVICE_CHARGE_DETAILS"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "serviceChargeDetails",
    fields: [
      {
        label: "Do you want to add a periodic service charge?",
        key: "perServChar",
        value: "",
      },
      {
        label:
          "How often should the periodic service charge be applied to the position?",
        key: "svcChrgFreq",
        value: "",
      },
      {
        label: "What are the minimum and the maximum periodic service charges?",
        key: "minMax",
        value: "",
      },
      {
        label: "What is the minimum fee adjustment transaction code?",
        key: "feeMinTrnCode",
        value: "",
      },
      {
        label: "What is the maximum fee adjustment transaction code?",
        key: "feeMaxTrnCode",
        value: "",
      },
    ],
  },
  {
    name: "Earning analysis",
    stageKey: "earningAnalysis",
    link: ROUTE_PATH["FEE_EARNING_ANALYSIS"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "completed",
    fields: [
      {
        label: "How will excess earnings be handled?",
        key: "earningsAnalysis",
        value: "",
      },
    ],
  },
];

const SUMMARY_FEE_STEPS_LOAN: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Fee configuration",
    stageKey: "feeConfig",
    link: ROUTE_PATH["FEE_CONFIGURATION_LOAN"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "feeConfig",
    fields: [
      {
        label: "What is the fee detail?",
        key: "feeName",
        value: "",
      },
      {
        label: "What is the fee type option?",
        key: "feeTypeOpt",
        value: "",
      },
      {
        label: "Would you like to accumulate the fee(s)?",
        key: "isAccum",
        value: "",
      },
    ],
  },
  {
    name: "Service charge details",
    stageKey: "serviceChargeDetails",
    link: ROUTE_PATH["FEE_SERVICE_CHARGE_DETAILS_LOAN"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "serviceChargeDetails",
    fields: [
      {
        label: "Do you want to add a periodic service charge?",
        key: "perServChar",
        value: "",
      },
      {
        label:
          "How often should the periodic service charge be applied to the position?",
        key: "svcChrgFreq",
        value: "",
      },
      {
        label: "What are the minimum and the maximum periodic service charges?",
        key: "minMax",
        value: "",
      },
      {
        label: "What is the minimum fee adjustment transaction code?",
        key: "feeMinTrnCode",
        value: "",
      },
      {
        label: "What is the maximum fee adjustment transaction code?",
        key: "feeMaxTrnCode",
        value: "",
      },
    ],
  },
  {
    name: "Earning analysis",
    stageKey: "earningAnalysis",
    link: ROUTE_PATH["FEE_EARNING_ANALYSIS_LOAN"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "completed",
    fields: [
      {
        label: "How will excess earnings be handled?",
        key: "earningsAnalysis",
        value: "",
      },
    ],
  },
];

const SUMMARY_INTEREST: ProductSummaryType = {
  name: "Interest component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["INTEREST_GENERAL_FEATURE_FORM"],
  componentClass: COMPONENT_CLASS["interest"],
};

const SUMMARY_INTEREST_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Foundational Interest Details",
    stageKey: "foundationalInterestDetails",
    link: ROUTE_PATH["INTEREST_FOUNDATIONAL_INTEREST_DETAILS"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "foundationalInterestDetails",
    fields: [
      {
        label:
          "What calculation method should be used to calculate the daily accrued interest?",
        key: "calcMthd",
        value: "",
      },
      {
        label:
          "What position balance would you like to use to calculate the interest accrual?",
        key: "balOpt",
        value: "",
      },
      {
        label:
          "What is the minimum balance required to accrue interest on the position?",
        key: "accrMinBal",
        value: "",
      },
      {
        label: "What is the rate adjustment term?",
        key: "adjTerm",
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Foundational Details",
    link: ROUTE_PATH["INTEREST_INDEX_RATE_FOUND_RATE"],
    stageKey: "indexRateFoundationalDetails",
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateFoundationalDetails",
    fields: [
      {
        label: "Do you want to add an indexed rate detail?",
        key: "indexedRate",
        value: "",
      },
      {
        label: "What is the index name?",
        key: "indexName",
        value: "",
      },
      {
        label: "What is the first duration?",
        key: "firstDur",
        value: "",
      },
      {
        label: "What is the nominal rate?",
        key: "nomRate",
        isPercentage: true,
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Rate Adjustments and Ranges",
    stageKey: "indexRateAdjustmentRanges",
    link: ROUTE_PATH["INTEREST_INDEX_RATE_ADJUSTMENT"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateAdjustmentRanges",
    fields: [
      {
        label: "What are the minimum and maximum rates?",
        key: "maxRate",
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the initial adjustment period?",
        key: "maxChngFirst",
        isPercentage: true,
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the subsequent rate adjustment?",
        key: "maxChngNext",
        isPercentage: true,
        value: "",
      },
      {
        label:
          "What is the maximum rate change (ceiling rate) at each adjustment over the life of the product?",
        key: "maxChngLife",
        isPercentage: true,
        value: "",
      },
      {
        label: "What is the maximum change per instance?",
        key: "maxChngPer",
        isPercentage: true,
        value: "",
      },
      {
        label: "How often should a rate adjustment be applied? ",
        key: "reviewFreq",
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Rate Offset & Rounding",
    stageKey: "indexRateOffsetRounding",
    link: ROUTE_PATH["INTEREST_INDEX_RATE_REVIEW_OFFSET"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateOffsetRounding",
    fields: [
      {
        label: "Add rate offset?",
        key: "rateOffset",
        value: "",
      },
      {
        label: "What is the rate offset value?",
        key: "offsetMatrix",
        value: "",
      },
      {
        label: "What is the rounding expression?",
        key: "round",
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Review Frequency",
    stageKey: "indexRateReviewFrequency",
    link: ROUTE_PATH["INTEREST_INDEX_RATE_REVIEW_FREQUENCY"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateReviewFrequency",
    fields: [
      {
        label:
          "Is there a review of the interest rate acquired from index daily at accrual cutoff?",
        key: "isReviewDly",
        value: "",
      },
      {
        label: "How often should the interest rate be reviewed?",
        key: "reviewFreq",
        value: "",
      },
      {
        label: "When is the next index review date/time?",
        key: "nextReviewDtm",
        value: "",
      },
    ],
  },
  {
    name: "Promotional Rate - Foundational Details",
    stageKey: "promotionalRateFoundationDetails",
    link: ROUTE_PATH["INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promotionalRateFoundationDetails",
    fields: [
      {
        label:
          "Do you want to add a promotional rate detail with an indexed rate?",
        key: "promotionalRate",
        value: "",
      },
      {
        label: "What is the index name?",
        key: "indexName",
        value: "",
      },
      {
        label: "What is the first duration?",
        key: "firstDur",
        value: "",
      },
      {
        label: "What is the promotional code?",
        key: "promoCode",
        value: "",
      },
      {
        label: "What is the promotional rate term?",
        key: "promoTerm",
        value: "",
      },
      {
        label: "What is the nominal rate?",
        key: "nomRate",
        isPercentage: true,
        value: "",
      },
    ],
  },
  {
    name: "Promotional Rate – Rate Adjustments and Ranges",
    stageKey: "promoRateAdjustmentsRanges",
    link: ROUTE_PATH["INTEREST_PROMOTIONAL_RATE_ADJUST_RANGE"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promoRateAdjustmentsRanges",
    fields: [
      {
        label:
          "What are the minimum nominal rate floor and maximum promotional rate (or rate ceiling)?",
        key: "maxRate",
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the initial adjustment period?",
        key: "maxChngFirst",
        isPercentage: true,
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the subsequent rate adjustment?",
        key: "maxChngNext",
        isPercentage: true,
        value: "",
      },
      {
        label: "How often should a rate adjustment be applied? ",
        key: "nextChngFreq",
        value: "",
      },
    ],
  },
  {
    name: "Promotional Rate - Rate Offset & Rounding",
    stageKey: "promoRateOffsetsRounding",
    link: ROUTE_PATH["INTEREST_PROMOTIONAL_RATE_OFFSET_TERMS"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promoRateOffsetsRounding",
    fields: [
      {
        label: "Add rate offset?",
        key: "rateOffset",
        value: "",
      },
      {
        label: "What is the rate offset value?",
        key: "offsetMatrix",
        value: "",
      },
      {
        label: "What is the rounding expression?",
        key: "round",
        value: "",
      },
    ],
  },
  {
    name: "Posting Frequency & Rounding",
    stageKey: "promoPostingFrequencyRounding",
    link: ROUTE_PATH["INTEREST_POST_FREQUENCY_ROUNDING"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promoPostingFrequencyRounding",
    fields: [
      {
        label: "What is disbursement option?",
        key: "disbmtOpt",
        value: "",
      },
      {
        label: "Is there interest posting?",
        key: "isPostNetInt",
        value: "",
      },
      {
        label: "What is the posting frequency?",
        key: "postFreq",
        value: "",
      },
      {
        label: "Select an interest posting round option.",
        key: "postRoundOpt",
        value: "",
      },
    ],
  },
  {
    name: "Interest Accrual Details",
    stageKey: "interestAccrualDetails",
    link: ROUTE_PATH["INTEREST_ACCURAL_RATE"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "completed",
    fields: [
      {
        label: "Is there a negative accrued option?",
        key: "negAccrOpt",
        value: "",
      },
      {
        label: "What is the accrual calculation time?",
        key: "accrCalcTm",
        value: "",
      },
      {
        label: "Select an interest accrual carryover option.",
        key: "accrCarryOverOpt",
        value: "",
      },
    ],
  },
];

const SUMMARY_INTEREST_STEPS_LOAN: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Foundational Interest Details",
    stageKey: "foundationalInterestDetails",
    link: ROUTE_PATH["INTEREST_LOAN_FOUNDATIONAL_INTEREST_DETAILS"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "foundationalInterestDetails",
    fields: [
      {
        label:
          "What calculation method should be used to calculate the daily accrued interest?",
        key: "calcMthd",
        value: "",
      },
      {
        label:
          "What position balance would you like to use to calculate the interest accrual?",
        key: "balOpt",
        value: "",
      },
      {
        label:
          "What is the minimum balance required to accrue interest on the position?",
        key: "accrMinBal",
        value: "",
      },
      {
        label: "What is the rate adjustment term?",
        key: "adjTerm",
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Foundational Details",
    link: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_FOUND_DETAIL"],
    stageKey: "indexRateFoundationalDetails",
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateFoundationalDetails",
    fields: [
      {
        label: "Do you want to add an indexed interest rate?",
        key: "indexedRate",
        value: "",
      },
      {
        label: "What is the index name?",
        key: "indexName",
        value: "",
      },
      {
        label: "What is the first duration?",
        key: "firstDur",
        value: "",
      },
      {
        label: "What is the nominal rate?",
        key: "nomRate",
        isPercentage: true,
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Rate Adjustments and Ranges",
    stageKey: "indexRateAdjustmentRanges",
    link: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_ADJUSTMENT"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateAdjustmentRanges",
    fields: [
      {
        label: "What are the minimum and maximum rates?",
        key: "maxRate",
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the initial adjustment period?",
        key: "maxChngFirst",
        isPercentage: true,
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the subsequent rate adjustment?",
        key: "maxChngNext",
        isPercentage: true,
        value: "",
      },
      {
        label:
          "What is the maximum rate change (ceiling rate) at each adjustment over the life of the product?",
        key: "maxChngLife",
        isPercentage: true,
        value: "",
      },
      {
        label: "What is the maximum change per instance?",
        key: "maxChngPer",
        isPercentage: true,
        value: "",
      },
      {
        label: "How often should a rate adjustment be applied? ",
        key: "reviewFreq",
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Rate Offset & Rounding",
    stageKey: "indexRateOffsetRounding",
    link: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_REVIEW_OFFSET"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateOffsetRounding",
    fields: [
      {
        label: "Add rate offset?",
        key: "rateOffset",
        value: "",
      },
      {
        label: "What is the rate offset value?",
        key: "offsetMatrix",
        value: "",
      },
      {
        label: "What is the rounding expression?",
        key: "round",
        value: "",
      },
    ],
  },
  {
    name: "Indexed Rate – Review Frequency",
    stageKey: "indexRateReviewFrequency",
    link: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_REVIEW_FREQUENCY"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "indexRateReviewFrequency",
    fields: [
      {
        label:
          "Is there a review of the interest rate acquired from index daily at accrual cutoff?",
        key: "isReviewDly",
        value: "",
      },
      {
        label: "How often should the interest rate be reviewed?",
        key: "reviewFreq",
        value: "",
      },
      {
        label: "When is the next index review date/time?",
        key: "nextReviewDtm",
        value: "",
      },
    ],
  },
  {
    name: "Introduction Rate - Foundational Details",
    stageKey: "promotionalRateFoundationDetails",
    link: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promotionalRateFoundationDetails",
    fields: [
      {
        label:
          "Do you want to add a promotional rate detail with an indexed rate?",
        key: "promotionalRate",
        value: "",
      },
      {
        label: "What is the index name?",
        key: "indexName",
        value: "",
      },
      {
        label: "What is the first duration?",
        key: "firstDur",
        value: "",
      },
      {
        label: "What is the introduction (promotional) code?",
        key: "promoCode",
        value: "",
      },
      {
        label: "What is the promotional rate term?",
        key: "promoTerm",
        value: "",
      },
      {
        label: "What is the nominal rate?",
        key: "nomRate",
        isPercentage: true,
        value: "",
      },
    ],
  },
  {
    name: "Introduction Rate – Rate Adjustments and Ranges",
    stageKey: "promoRateAdjustmentsRanges",
    link: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_RATE_ADJUST_RANGE"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promoRateAdjustmentsRanges",
    fields: [
      {
        label:
          "What are the minimum nominal rate floor and maximum promotional rate (or rate ceiling)?",
        key: "maxRate",
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the initial adjustment period?",
        key: "maxChngFirst",
        isPercentage: true,
        value: "",
      },
      {
        label:
          "What is the maximum allowable rate adjustment during the subsequent rate adjustment?",
        key: "maxChngNext",
        isPercentage: true,
        value: "",
      },
      {
        label: "How often should a rate adjustment be applied? ",
        key: "nextChngFreq",
        value: "",
      },
    ],
  },
  {
    name: "Introduction Rate - Rate Offset & Rounding",
    stageKey: "promoRateOffsetsRounding",
    link: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_RATE_OFFSET_TERMS"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promoRateOffsetsRounding",
    fields: [
      {
        label: "Add rate offset?",
        key: "rateOffset",
        value: "",
      },
      {
        label: "What is the rate offset value?",
        key: "offsetMatrix",
        value: "",
      },
      {
        label: "What is the rounding expression?",
        key: "round",
        value: "",
      },
    ],
  },
  {
    name: "Posting Frequency & Rounding",
    stageKey: "promoPostingFrequencyRounding",
    link: ROUTE_PATH["INTEREST_LOAN_POST_FREQUENCY_ROUNDING"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "promoPostingFrequencyRounding",
    fields: [
      {
        label: "Is there interest posting?",
        key: "isPostNetInt",
        value: "",
      },
      {
        label: "What is the posting frequency?",
        key: "postFreq",
        value: "",
      },
      {
        label: "Select an interest posting round option.",
        key: "postRoundOpt",
        value: "",
      },
    ],
  },
  {
    name: "Interest Accrual Details",
    stageKey: "interestAccrualDetails",
    link: ROUTE_PATH["INTEREST_LOAN_ACCURAL_RATE"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "completed",
    fields: [
      {
        label: "Is there a negative accrued option?",
        key: "negAccrOpt",
        value: "",
      },
      {
        label: "What is the accrual calculation time?",
        key: "accrCalcTm",
        value: "",
      },
      {
        label: "Select an interest accrual carryover option.",
        key: "accrCarryOverOpt",
        value: "",
      },
    ],
  },
];

const SUMMARY_LIMIT: ProductSummaryType = {
  name: "Limit component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["LIMIT_GENERAL_FEATURE_FORM"],
  componentClass: COMPONENT_CLASS["limit"],
};
const SUMMARY_LIMIT_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Account Balance Configuration",
    link: ROUTE_PATH["LIMIT_ACCOUNT_BALANCE_CONFIG"],
    stageKey: "accoutnBalanceConfiguration",
    status: PRODUCT_STATUS.not_started,
    stageStatus: "accoutnBalanceConfiguration",
    fields: [
      {
        label: "Do you want to require a minimum balance?",
        key: "isMinBalRequired",
        value: "",
      },
      {
        label: "What is the minimum required balance?",
        key: "minBal",
        value: "",
      },
      {
        label: "What is the minimum amount to open?",
        key: "minToOpen",
        value: "",
      },
      {
        label: "What is the maximum balance?",
        key: "maxBal",
        value: "",
      },
      {
        label: "What is the de minimis amount?",
        key: "deminimisAmt",
        value: "",
      },
      {
        label: "What is the minimum balance fee?",
        key: "minBalFee",
        value: "",
      },
      {
        label: "What is the minimum balance option?",
        key: "minBalOpt",
        value: "",
      },
    ],
  },
  {
    name: "Restriction Configuration",
    stageKey: "restrictionConfiguration",
    link: ROUTE_PATH["LIMIT_RESTRICTION_CONFIG"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "restrictionConfiguration",
    fields: [
      {
        label: "Do you want to restrict credits after funding expiration?",
        key: "restrictCrFundExp",
        value: "",
      },
      {
        label: "Do you want to restrict all credits?",
        key: "restrictCr",
        value: "",
      },
      {
        label: "Do you want to restrict all debits?",
        key: "restrictDr",
        value: "",
      },
    ],
  },
  {
    name: "Accumulated Transaction Limit Configuration",
    stageKey: "accumulatedTransactionLimitConfiguration",
    link: ROUTE_PATH["LIMIT_ACCUMULAT_TRANSCATION_CONFIG"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "accumulatedTransactionLimitConfiguration",
    fields: [
      {
        label: "What is the limit name?",
        key: "name",
        value: "",
      },
      {
        label: "Who is the limit defined by?",
        key: "definedBy",
        value: "",
      },
      {
        label: "What is the accumulation group?",
        key: "statGroup",
        value: "",
      },
      {
        label: "What is the start date?",
        key: "startDtm",
        value: "",
      },
      {
        label: "What is the maximum credit amount for the period?",
        key: "crAmt",
        value: "",
      },
      {
        label: "What is the maximum credit count for the period?",
        key: "crCnt",
        value: "",
      },
      {
        label: "What is the maximum debit amount for the period?",
        key: "drAmt",
        value: "",
      },
      {
        label: "What is the maximum debit count for the period?",
        key: "drCnt",
        value: "",
      },
      {
        label: "What is the maximum total amount for the period?",
        key: "totAmt",
        value: "",
      },
      {
        label: "What is the maximum total transaction count for the period?",
        key: "totCnt",
        value: "",
      },
      {
        label: "If violated, what is the violation action?",
        key: "violationAct",
        value: "",
      },
      {
        label: "Do you want to charge a fee for violating the minimum balance?",
        key: "voilationFeeButton",
        value: "",
      },
      {
        label: "What is the fee for a violation?",
        key: "violationFee",
        value: "",
      },
    ],
  },
  {
    name: "Single Transaction Limit Configuration",
    stageKey: "singleTransactionLimitConfig",
    link: ROUTE_PATH["LIMIT_SINGLE_TRANSACTION_CONFIG"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "completed",
    fields: [
      {
        label: "What is the limit name?",
        key: "name",
        value: "",
      },
      {
        label: "Who is the limit defined by?",
        key: "definedBy",
        value: "",
      },
      {
        label:
          "What are the minimum and maximum per transaction credit amounts?",
        key: "maxCrAmt",
        value: "",
      },
      {
        label:
          "What are the minimum and maximum per transaction debit amounts?",
        key: "maxDrAmt",
        value: "",
      },
      {
        label: "What is the incremental per transaction debit amount?",
        key: "increDrAmt",
        value: "",
      },
      {
        label: "What is the incremental per transaction credit amount?",
        key: "increCrAmt",
        value: "",
      },
      {
        label: "If violated, what is the violation action?",
        key: "violationAct",
        value: "",
      },
      {
        label: "Do you want to charge a fee for violating the minimum balance?",
        key: "voilationFeeButton",
        value: "",
      },
      {
        label: "What is the fee for a violation?",
        key: "violationFee",
        value: "",
      },
      {
        label: "Select the excluded transaction code(s)",
        key: "trnCodeExcl",
        value: "",
      },
    ],
  },
];

const SUMMARY_REWARD: ProductSummaryType = {
  name: "Rewards component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["REWARD_GENERAL_FEATURE_FORM"],
  componentClass: COMPONENT_CLASS["reward"],
};
const SUMMARY_REWARD_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Reward Configuration",
    stageKey: "rewardConfiguration",
    link: ROUTE_PATH["REWARD_CONFIGURATION_FORM"],
    status: PRODUCT_STATUS.not_started,
    stageStatus: "completed",
    fields: [
      {
        label: "What kind of reward would you like to configure?",
        key: "configuration",
        value: "",
      },
      {
        label: "What is the payout option program type?",
        key: "programType",
        value: "",
      },
      {
        label: "What is the deposit interval?",
        key: "depInterval",
        value: "",
      },
      {
        label: "What is the interval amount?",
        key: "intervalAmt",
        value: "",
      },
      {
        label: "What is the goal term?",
        key: "goalTerm",
        value: "",
      },
      {
        label: "What is the savings goal amount?",
        key: "goalAmt",
        value: "",
      },
      {
        label: "What is the product program?",
        key: "program",
        value: "",
      },
      {
        label: "What is the product program group?",
        key: "subGroups",
        value: "",
      },
      {
        label: "What is the reward amount?",
        key: "reward",
        value: "",
      },
      {
        label: "What is the reward interest premium percentage?",
        key: "rewardIntPrem",
        value: "",
      },
      {
        label: "How often do you want to pay out the transaction reward?",
        key: "payoutFreq",
        value: "",
      },
      {
        label: "What is the transaction reward payout transaction code?",
        key: "payoutTrnCode",
        value: "",
      },
      {
        label: "What is the reward review frequency?",
        key: "reviewFreq",
        value: "",
      },
    ],
  },
];

const SUMMARY_NSF: ProductSummaryType = {
  name: "NSF component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["NSF_GENERAL_SETUP"],
  componentClass: COMPONENT_CLASS["nsf"],
};
const SUMMARY_NSF_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Negative Limit Details",
    stageKey: "negativeLimitDetails",
    status: PRODUCT_STATUS.not_started,
    stageStatus: "negativeLimitDetails",
    link: ROUTE_PATH["NSF_NEGATIVE_LIMITS"],
    fields: [
      {
        label: "What is the overdraft limit?",
        key: "negLimitMatrix",
        value: "",
      },
      {
        label:
          "How often would you like negative limit frequency balance checks to occur?",
        key: "negLimitFreq",
        value: "",
      },
      {
        label: "What is the minimum overdraft amount?",
        key: "negLimitMin",
        value: "",
      },
      {
        label: "What is the maximum overdraft amount?",
        key: "negLimitMax",
        value: "",
      },
      {
        label: "What is the fixed overdraft limit amount?",
        key: "negLimit",
        value: "",
      },
      {
        label: "When should overdraft protection start?",
        key: "negLimitStartOpt",
        value: "",
      },
      {
        label: "Set the duration from open date",
        key: "negLimitOpenDur",
        value: "",
      },
      {
        label:
          "Should customers be automatically opted-in for overdraft protection?",
        key: "isNegLimitOptIn",
        value: "",
      },
      {
        label: "Automate overdraft approvals?",
        key: "isAutoAuth",
        value: "",
      },
      {
        label: "Is the NSF fee waived?",
        key: "isWaiveNsfFee",
        value: "",
      },
      {
        label: "Will an NSF fee be charged if within the negative limit?",
        key: "isNegLimitNsfFee",
        value: "",
      },
      {
        label: "What are the negative balance alert days?",
        key: "negBalAlertDays",
        value: "",
      },
      {
        label:
          "How many days can a position have non-sufficient fund status before debit restrictions kick in?",
        key: "nsfDrRestrictDays",
        value: "",
      },
      {
        label: "What restriction is placed on the position?",
        key: "nsfDrRestrict",
        value: "",
      },
      {
        label:
          "How many days must an position have a positive balance for before the NSF debit restriction is lifted?",
        key: "nsfDrRestrictPosBalDays",
        value: "",
      },
    ],
  },
  {
    name: "Transaction Code Specifications",
    stageKey: "nsfTransactionCodeSpecifications",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["NSF_TRANSACTIONAL_CODE"],
    stageStatus: "completed",
    fields: [
      {
        label: "What transaction code(s) are excluded?",
        key: "trnCodeExcl",
        value: "",
      },
    ],
  },
];

const SUMMARY_REPAY: ProductSummaryType = {
  name: "Repayment component (loan)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["REPAYMENT_GENERAL_FEATURE_FORM"],
  componentClass: COMPONENT_CLASS["repay"],
};
const SUMMARY_REPAY_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Interest and principal configuration",
    stageKey: "principlaInterestConfig",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["REPAYMENT_PRINCIPAL_INTEREST_FORM"],
    stageStatus: "principlaInterestConfig",
    fields: [
      {
        label: "Choose a method for calculating principal payments",
        key: "prinMthd",
        value: "",
      },
      {
        label: "What is the fixed amount for principal repayment?",
        key: "fixPrinAmt",
        value: "",
      },
      {
        label: "How will interest be calculated?",
        key: "intMthd",
        value: "",
      },
      {
        label: "What is the fixed interest amount?",
        key: "fixIntAmt",
        value: "",
      },
      {
        label: "What percentage of the balance should be used for calculation?",
        key: "prinBalPct",
        isPercentage: true,
        value: "",
      },
      {
        label: "What is the balance base?",
        key: "prinBalBase",
        value: "",
      },
      {
        label: "What is the odd days interest option?",
        key: "oddDaysIntOpt",
        value: "",
      },
      {
        label: "What is the amortization duration?",
        key: "amortizeDur",
        value: "",
      },
    ],
  },
  {
    name: "Payment Configuration",
    stageKey: "paymentConfig",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["REPAYMENT_PAYMENT_CONFIGURATION_FORM"],
    stageStatus: "paymentConfig",
    fields: [
      {
        label: "What is the payment duration?",
        key: "pmtDur",
        value: "",
      },
      {
        label: "How often should loan payments be made?",
        key: "pmtFreq",
        value: "",
      },
      {
        label: "How long until the repayment due date of the receivable?",
        key: "pmtOffset",
        value: "",
      },
      {
        label: "What is the minimum payment amount?",
        key: "minPmtAmt",
        value: "",
      },
      {
        label:
          "What is the acceptable variance amount between payoff and an outstanding balance?",
        key: "payoffVarianceAmt",
        value: "",
      },
      {
        label: "What is the payment grace period?",
        key: "gracePeriod",
        value: "",
      },
      {
        label: "Select payment tolerance type",
        //toleranceAmt
        key: "tolerancePct",
        isPercentage: true,
        value: "",
      },
      {
        label: "Is the payment revolving?",
        key: "isRevolving",
        value: "",
      },
      {
        label: "What is the payment application matrix?",
        key: "payApplMatrix",
        value: "",
      },
      {
        label: "What is the advance payment application method?",
        key: "pmtApplAdvance",
        value: "",
      },
    ],
  },
  {
    name: "Payment application method",
    stageKey: "paymentDirectionAndCharges",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["REPAYMENT_PAYMENT_DIRECTION_CHARGES"],
    stageStatus: "paymentDirectionAndCharges",
    fields: [
      {
        label: "Add priority as a key for the new payment application method?",
        key: "priorityPaymentApplicationMethod",
        value: "",
      },
      {
        label: "What is the direction?",
        key: "priorityDirection",
        value: "",
      },
      {
        label: "What order should be added?",
        key: "priorityDueItemOrder",
        value: "",
      },
      {
        label: "Drag the selections to prioritize the list.",
        key: "priorityDueItemOrder",
        value: "",
      },
      {
        label: "Add past as a key for the new payment application method?",
        key: "pastPaymentApplicationMethod",
        value: "",
      },
      {
        label: "What is the direction?",
        key: "pastDirection",
        value: "",
      },
      {
        label: "What order should be added?",
        key: "pastDueItemOrder",
        value: "",
      },
      {
        label: "Drag the selections to prioritize the list.",
        key: "pastDueItemOrder",
        value: "",
      },
      {
        label: "Add current as a key for the new payment application method?",
        key: "currentPaymentApplicationMethod",
        value: "",
      },
      {
        label: "What is the direction?",
        key: "currentDirection",
        value: "",
      },
      {
        label: "What order should be added?",
        key: "currentDueItemOrder",
        value: "",
      },
      {
        label: "Drag the selections to prioritize the list.",
        key: "currentDueItemOrder",
        value: "",
      },
    ],
  },
  {
    name: "Pre-payment configurations",
    stageKey: "prePaymentConfigurations",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["REPAYMENT_PRE_PAYMENT_DIRECTION_CHARGES"],
    stageStatus: "prePaymentConfigurations",
    fields: [
      {
        label: "What is the pre-payment minimum amount?",
        key: "prePmtMinAmt",
        value: "",
      },
      {
        label: "What is the pre-payment minimum percentage?",
        key: "prePmtMinPct",
        isPercentage: true,
        value: "",
      },
    ],
  },
  {
    name: "Due item configuration",
    stageKey: "pastDueConfigTracking",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["REPAYMENT_PAST_DUE_CONFIGURATIONS_AND_TRACKING"],
    stageStatus: "pastDueConfigTracking",
    fields: [
      {
        label: "What is the name of the due item?",
        key: "itemName",
        value: "",
      },
      {
        label: "What is the due item description?",
        key: "description",
        value: "",
      },
      {
        label: "What are the sub balance details?",
        key: "subBal",
        value: "",
      },
      {
        label: "What are the tolerance percentage details?",
        key: "tolerancePct",
        isPercentage: true,
        value: "",
      },
      {
        label: "What is the tolerance amount?",
        key: "toleranceAmt",
        value: "",
      },
      {
        label: "Should this due item be generated as a standalone receivable?",
        key: "isReceivable",
        value: "",
      },
      {
        label: "What is the calculation method?",
        key: "calcMthd",
        value: "",
      },
    ],
  },
  {
    name: "Advanced component options",
    stageKey: "advancedComponentOptions",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["REPAYMENT_ADVANCED_COMPONENT_OPTIONS"],
    stageStatus: "completed",
    fields: [
      {
        label: "Does the component bill include capitalized interest?",
        key: "billCapInt",
        value: "",
      },
      {
        label: "Does the component have an automatic payment upon maturity?",
        key: "isMaturityAutoPmt",
        value: "",
      },
      {
        label: "Will the component be real time receivable?",
        key: "isRtReceivable",
        value: "",
      },
      {
        label: "Will the component have unapplied funds?",
        key: "isUnapplFunds",
        value: "",
      },
    ],
  },
];

const SUMMARY_COLLATERAL: ProductSummaryType = {
  name: "Collateral component (loan)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["COLLATERAL_GENERAL_COMPONENT_SETUP"],
  componentClass: COMPONENT_CLASS["collateral"],
};
const SUMMARY_COLLATERAL_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Security Specification",
    stageKey: "securitySpecification",
    status: PRODUCT_STATUS.not_started,
    stageStatus: "completed",
    link: ROUTE_PATH["COLLATERAL_SECURITY_SECIFICATIONS_OPTIONS"],
    fields: [
      {
        label: "What is the collateral security level?",
        key: "secLvl",
        value: "",
      },
      {
        label:
          "What is the percentage of the loan that is covered by securities?",
        key: "secPct",
        isPercentage: true,
        value: "",
      },
    ],
  },
];

const SUMMARY_TERM: ProductSummaryType = {
  name: "Term component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["TERM_DEPOSIT_GENERAL_SETUP"],
  componentClass: COMPONENT_CLASS["term"],
};
const SUMMARY_TERM_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Maturity Configuration",
    stageKey: "maturityConfiguration",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["TERM_DEPOSIT_MATURITY_CONFIGURATION"],
    stageStatus: "maturityConfiguration",
    fields: [
      {
        label:
          "How long before the maturity date do you want to alert the customer of the impending maturity?",
        key: "notice",
        value: "",
      },
      {
        label: "What are the available options at maturity?",
        key: "maturityOpt",
        value: "",
      },
    ],
  },
  {
    name: "Rollover Product Interest and Rates Configuration",
    stageKey: "rollOverProdIntrestRateConfig",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["TERM_DEPOSIT_ROLL_OVER_PRODUCT"],
    stageStatus: "rollOverProdIntrestRateConfig",
    fields: [
      {
        label: "What is the rollover product?",
        key: "rollProd",
        value: "",
      },
    ],
  },
  {
    name: "Grace Periods and Extensions",
    stageKey: "gracePeriodExtensions",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["TERM_DEPOSIT_GRACE_PERIODS"],
    stageStatus: "gracePeriodExtensions",
    fields: [
      {
        label: "What is the debit grace period?",
        key: "rollDrGrace",
        value: "",
      },
      {
        label: "What is the debit grace period interest adjustment?",
        key: "rollDrGraceAdj",
        value: "",
      },
      {
        label: "How long is the rollover credit grace period?",
        key: "rollCrGrace",
        value: "",
      },
      {
        label: "What is the credit term extension?",
        key: "crTermExt",
        value: "",
      },
    ],
  },
  {
    name: "Penalties and Adjustments",
    stageKey: "penaltiesAdjustments",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["TERM_DEPOSIT_PENALTIES_ADJUSTMENT"],
    stageStatus: "completed",
    fields: [
      {
        label: "What is the early withdrawal penalty?",
        key: "earlyDrPen",
        value: "",
      },
      {
        label: "What is the penalty matrix?",
        key: "penMatrix",
        value: "",
      },
      {
        label: "What is the penalty transaction code?",
        key: "penTrnCode",
        value: "",
      },
      {
        label: "Set the number of bump lead days",
        key: "bumpLeadDays",
        value: "",
      },
      {
        label: "Will this component skip interest posting?",
        key: "skipIntPost",
        value: "",
      },
    ],
  },
];

const SUMMARY_CHARGE_OF: ProductSummaryType = {
  name: "Charge-off component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["CHARGE_OFF_GENERAL_SETUP"],
  componentClass: COMPONENT_CLASS["chargeOff"],
};
const SUMMARY_CHARGE_OF_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Charge-off Timing and Thresholds",
    stageKey: "chargeOffTimingThreshold",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["CHARGE_OFF_TIMING_AND_THRESHOLD"],
    stageStatus: "chargeOffTimingThreshold",
    fields: [
      {
        label:
          "How many days before a deposit position with a negative balance is automatically charged off?",
        key: "chrgOffDays",
        value: "",
      },
      {
        label:
          "What is the minimum negative balance required for a deposit position to be eligible for automatic charge-off?",
        key: "chrgOffMin",
        value: "",
      },
      {
        label:
          "After an automatic charge-off, should the deposit position be closed, or should it remain open?",
        key: "chrgOffOpt",
        value: "",
      },
    ],
  },
  {
    name: "Transaction Code Specifications",
    stageKey: "chargeTransactionCodeSpecifications",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["CHARGE_OFF_TRANSACTIONAL_CODE"],
    stageStatus: "completed",
    fields: [
      {
        label: "What is the charge-off transaction code",
        key: "transactionCodeValue",
        value: "",
      },
      {
        label: "Select charge off transaction code field?",
        key: "trnCode",
        value: "",
      },
      {
        label: "Select charge off transaction code matrix?",
        key: "trnCodeMatrix",
        value: "",
      },
    ],
  },
];

const SUMMARY_RULES: ProductSummaryType = {
  name: "Rule component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["RULES"],
  componentClass: COMPONENT_CLASS["rules"],
};

const SUMMARY_TRANSACTION: ProductSummaryType = {
  name: "Transaction component (deposit)",
  featureName: "[Component_name]",
  version: 1,
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["TRANSACTION_GENERAL_SETUP"],
  componentClass: COMPONENT_CLASS["transactionDeposit"],
};
const SUMMARY_TRANSACTION_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Transaction Code Specifications",
    stageKey: "transactionCodeSpecifications",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["TRANSACTION_TRANSACTIONAL_CODE"],
    stageStatus: "completed",
    fields: [
      {
        label:
          "What is the transaction code that defines the round up functionality?",
        key: "roundUpTrnCode",
        value: "",
      },
      {
        label: "What are the transaction codes eligible for round up?",
        key: "inclRoundUpTrnCodes",
        value: "",
      },
    ],
  },
];

const SUMMARY_RULES_STEPS: ProductSummaryFeatureDetailSteps[] = [
  {
    name: "Rule groups",
    stageKey: "rules",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["RULES"],
    stageStatus: "completed",
    fields: [
      {
        label: "Event name",
        key: "event",
        value: "",
      },
      {
        label: "Execute at",
        key: "executeAt",
        value: "",
      },
      {
        label: "Execute frequency",
        key: "executeFreq",
        value: "",
      },
      {
        label: "Group name",
        key: "groupName",
        value: "",
      },
      {
        label: "Next execute date",
        key: "nextExecuteDtm",
        value: "",
      },
      {
        label: "Remaining count",
        key: "remainCnt",
        value: "",
      },
      {
        label: "Rule group",
        key: "ruleGroup",
        value: "",
        subFields: [
          {
            label: "Rule name",
            key: "ruleName",
            value: "No rule name",
          },
        ],
      },
    ],
  },
];

const REGULATORY_ADHERENCE_LIMITATIONS_SAV: ProductSummaryType = {
  name: "Regulatory adherence & limitations",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE"],
  componentClass: "regulatoryAdherenceLimitations",
  steps: [
    {
      name: "",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_INSTALLMENT_REGULATORY_ADHERECE"],
      fields: [
        {
          label: "Is federal withholding exempt?",
          key: "isFedExempt",
          value: "",
        },
        {
          label: "Is state withholding exempt?",
          key: "isStateExempt",
          value: "",
        },
      ],
    },
  ],
};

const REGULATORY_ADHERENCE_LIMITATIONS_DDA: ProductSummaryType = {
  name: "Regulatory adherence & limitations",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE"],
  componentClass: "regulatoryAdherenceLimitations",
  steps: [
    {
      name: "",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_INSTALLMENT_REGULATORY_ADHERECE"],
      fields: [
        {
          label: "Is federal withholding exempt?",
          key: "isFedExempt",
          value: "",
        },
        {
          label: "Is state withholding exempt?",
          key: "isStateExempt",
          value: "",
        },
        {
          label: "Is this an NRA withholding exempt product?",
          key: "isNraExempt",
          value: "",
        },
      ],
    },
  ],
};

const REGULATORY_ADHERENCE_LIMITATIONS_REVOLVING: ProductSummaryType = {
  name: "Regulatory adherence & limitations",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE"],
  componentClass: "regulatoryAdherenceLimitations",
  steps: [
    {
      name: "",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_INSTALLMENT_REGULATORY_ADHERECE"],
      fields: [
        {
          label: "Add a maximum LTV percentage?",
          key: "maxLvtPercentageButton",
          value: "",
        },
        {
          label: "What is the maximum LTV percentage?",
          key: "maxLtvPct",
          isPercentage: true,
          value: "",
        },
        {
          label: "Is federal withholding exempt?",
          key: "isFedExempt",
          value: "",
        },
        {
          label: "Is state withholding exempt?",
          key: "isStateExempt",
          value: "",
        },
        {
          label: "Is this an NRA withholding exempt product?",
          key: "isNraExempt",
          value: "",
        },
      ],
    },
  ],
};

const REGULATORY_ADHERENCE_LIMITATIONS_INSTALL: ProductSummaryType = {
  name: "Regulatory adherence & limitations",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE"],
  componentClass: "regulatoryAdherenceLimitations",
  steps: [
    {
      name: "",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_INSTALLMENT_REGULATORY_ADHERECE"],
      fields: [
        {
          label: "Add a maximum LTV percentage?",
          key: "maxLtvPctButton",
          value: "",
        },
        {
          label: "What is the maximum LTV percentage?",
          key: "maxLtvPct",
          isPercentage: true,
          value: "",
        },
        {
          label: "Is federal withholding exempt?",
          key: "isFedExempt",
          value: "",
        },
        {
          label: "Is state withholding exempt?",
          key: "isStateExempt",
          value: "",
        },
        {
          label: "Is this an NRA withholding exempt product?",
          key: "isNraExempt",
          value: "",
        },
      ],
    },
  ],
};

const REGULATORY_ADHERENCE_LIMITATIONS_CD: ProductSummaryType = {
  name: "Regulatory adherence & limitations",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE"],
  componentClass: "regulatoryAdherenceLimitations",
  steps: [
    {
      name: "",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_INSTALLMENT_REGULATORY_ADHERECE"],
      fields: [
        {
          label: "Is bump up enabled?",
          key: "isBumpEnabled",
          value: "",
        },
        {
          label: "Is rate guaranteed?",
          key: "isRateGuaranteed",
          value: "",
        },
        {
          label: "Is federal withholding exempt?",
          key: "isFedExempt",
          value: "",
        },
        {
          label: "Is state withholding exempt?",
          key: "isStateExempt",
          value: "",
        },
        {
          label: "Is this an NRA withholding exempt product?",
          key: "isNraExempt",
          value: "",
        },
      ],
    },
  ],
};

const REGULATORY_ADHERENCE_LIMITATIONS_PRODUCT_ATTRIBUTE: {
  [key: string]: ProductSummaryType;
} = {
  [IFX_ACCT_TYPE.SDA]: REGULATORY_ADHERENCE_LIMITATIONS_SAV,
  [IFX_ACCT_TYPE.CDA]: REGULATORY_ADHERENCE_LIMITATIONS_CD,
  [IFX_ACCT_TYPE.DDA]: REGULATORY_ADHERENCE_LIMITATIONS_DDA,
  [IFX_ACCT_TYPE.ILA]: REGULATORY_ADHERENCE_LIMITATIONS_INSTALL,
  [IFX_ACCT_TYPE.LOC]: REGULATORY_ADHERENCE_LIMITATIONS_REVOLVING,
};

const CADENCES_FORM: ProductSummaryType = {
  name: "Statements",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_CADENCES_FORM"],
  componentClass: "cadences",
  steps: [
    {
      name: "Statement cadence",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_CADENCES_FORM"],
      fields: [
        {
          label: "What is the statement frequency?",
          key: "stmtFreq",
          value: "",
        },
        {
          label: "What is the statement start date?",
          key: "stmtStartDtmOpt",
          value: "",
        },
      ],
    },
    {
      name: "Interim Updates",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_CADENCES_FORM"],
      fields: [
        {
          label: "Add a new interim statement frequency?",
          key: "interismStmtButton",
          value: "",
        },
        {
          label: "What is the interim statement frequency?",
          key: "interimStmtFreq",
          value: "",
        },
      ],
    },
  ],
};

const ELIGIBILITY_AND_COMPONENTS_CONFIGURATION: ProductSummaryType = {
  name: "Eligibility & features configuration",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH[
    "PRODUCT_ATTRIBUTE_ELIGIBILITY_AND_COMPONENTS_CONFIGURATION"
  ],
  componentClass: "eligibilityAndComponentsConfiguration",
  steps: [
    {
      name: "",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH[
        "PRODUCT_ATTRIBUTE_ELIGIBILITY_AND_COMPONENTS_CONFIGURATION"
      ],
      fields: [
        {
          label: "Credit Bureau Reportable Flag",
          key: "isCrBureau",
          value: "",
        },
        {
          label: "What is the servicer ID?",
          key: "svcrId",
          value: "",
        },
        {
          label: "What is credit bureau cycle frequency?",
          key: "crBureauCycleFreq",
          value: "",
        },
        {
          label: "What is the credit bureau first run option?",
          key: "crBureauFirstRunOpt",
          value: "",
        },
        {
          label: "Set the review term duration",
          key: "reviewTerm",
          value: "",
        },
      ],
    },
  ],
};

const SUMMARY_PRODUCT_ATTRIBUTES: ProductSummaryType[] = [
  {
    name: "Product basics",
    status: PRODUCT_STATUS.not_started,
    link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
    componentClass: "productBasics",
    steps: [
      {
        name: "Account Details",
        status: PRODUCT_STATUS.not_started,
        stageKey: "completed",
        link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
        fields: [
          {
            label: "What is the asset class identifier?",
            key: "assetClass",
            value: "",
          },
          {
            label: "What is the asset identifier?",
            key: "assetId",
            value: "",
          },
        ],
      },
      {
        name: "General Ledger Parameters",
        status: PRODUCT_STATUS.not_started,
        stageKey: "completed",
        link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
        fields: [
          {
            label: "Do you want a GL set code matrix?",
            key: "glSetMatrixName",
            value: "",
          },
          {
            label: "What is the General Ledger set code?",
            key: "glSetCode",
            value: "",
          },
        ],
      },
      {
        name: "Product Operational Dates",
        status: PRODUCT_STATUS.not_started,
        stageKey: "completed",
        link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
        fields: [
          {
            heading: "Start Date",
            label: "When would you like your product to become available",
            key: "avlStartDtm",
            value: "",
          },
          {
            heading: "End Date",
            label: "Select Available end date",
            key: "availableEndDate",
            value: "",
          },
          {
            label: "Time",
            key: "endTime",
            value: "",
          },
        ],
      },
    ],
  },
];

const PRODUCT_BASICS_ATTRIBUTES: ProductSummaryType = {
  name: "Product basics",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
  componentClass: "productBasics",
  steps: [
    {
      name: "Account Details",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
      fields: [
        {
          label: "What is the asset class?",
          key: "assetClass",
          value: "",
        },
        {
          label: "What is the asset identifier?",
          key: "assetId",
          value: "",
        },
      ],
    },
    {
      name: "General Ledger Parameters",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
      fields: [
        {
          label: "Do you want a GL set code matrix?",
          key: "glSetMatrixName",
          value: "",
        },
        {
          label: "What is the General Ledger set code?",
          key: "glSetCode",
          value: "",
        },
      ],
    },
    {
      name: "Product Operational Dates",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
      fields: [
        {
          heading: "Start Date",
          label: "When would you like your product to become available",
          key: "avlStartDtm",
          value: "",
        },
        {
          heading: "End Date",
          label: "Select Available end date",
          key: "availableEndDate",
          value: "",
        },
        {
          label: "Time",
          key: "endTime",
          value: "",
        },
      ],
    },
  ],
};

const ADDITIONAL_PRODUCT_DETAILS_FOR_DEPOSIT: ProductSummaryType = {
  name: "Inactivity & dormancy management",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_PRODUCT_FEATURES"],
  componentClass: "additionalProductFeatures",
  steps: [
    {
      name: "Inactivity Protocols",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_PRODUCT_FEATURES"],
      fields: [
        {
          label: "What is the inactive status duration?",
          key: "inactiveStatusDur",
          value: "",
        },
        {
          label: "What is the inactive status alert frequency?",
          key: "inactiveStatusAlertFreq",
          value: "",
        },
        {
          label: "Set an inactivity fee",
          key: "inactiveStatusFee",
          value: "",
        },
        {
          label: "Set the inactive status restriction code",
          key: "inactiveStatusRestrictCode",
          value: "",
        },
      ],
    },
    {
      name: "Dormancy Management",
      stageKey: "completed",
      status: PRODUCT_STATUS.not_started,
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_PRODUCT_FEATURES"],
      fields: [
        {
          label: "What is the dormant status duration?",
          key: "dormantStatusDur",
          value: "",
        },
        {
          label: "What is the dormant status alert frequency?",
          key: "dormantStatusAlertFreq",
          value: "",
        },
        {
          label: "Set a dormant status fee",
          key: "dormantStatusFee",
          value: "",
        },
        {
          label: "Set the dormant status restriction code",
          key: "dormantStatusRestrictCode",
          value: "",
        },
      ],
    },
    {
      name: "Additional Attributes",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_PRODUCT_FEATURES"],
      fields: [
        {
          label: "What is the funding term?",
          key: "fundTerm",
          value: "",
        },
        {
          label: "How long should an account stay at a zero balance before closing?",
          key: "zeroBalCloseDays",
          value: "",
        },
        {
          label: "How many days before closing should an alert be sent out?",
          key: "zeroBalAlertDays",
          value: "",
        },
        {
          label: "What is the annual percentage yield?",
          key: "apy",
          isPercentage: true,
          value: "",
        },
        {
          label: "What are the services offered with this product?",
          key: "svcs",
          value: "",
        },
      ],
    },
  ],
};

const LOAN_DELIQUENCY: ProductSummaryType = {
  name: "Loan delinquency",
  status: PRODUCT_STATUS.not_started,
  link: ROUTE_PATH["PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY"],
  componentClass: ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.loanDelinquency,
  steps: [
    {
      name: "Non-accrual attributes",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY"],
      fields: [
        {
          label:
            "What is the cutoff duration for a position to enter non-accrual status?",
          key: "cutoffDur",
          value: "",
        },
        {
          label:
            "What is the minimum past due amount for a position to enter non-accrual status?",
          key: "minDue",
          value: "",
        },
        {
          label:
            "Do you want to automatically trigger the non-accrual event based on the cutoff configuration?",
          key: "isAutoTrigger",
          value: "",
        },
        {
          label:
            "Do you want payments made to interest and other charges to be applied to principal until the book balance is paid off?",
          key: "isPaidToPrin",
          value: "",
        },
        {
          label: "Allow system to generate receivables in non-accrual?",
          key: "isReceivable",
          value: "",
        },
        {
          label: "How many days past due before debits are restricted?",
          key: "pastDueRestrictDays",
          value: "",
        },
      ],
    },
    {
      name: "Past-due term",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY"],
      fields: [
        {
          label: "What is the past due term?",
          key: "pastDueTerms",
          value: "",
        },
      ],
    },
    {
      name: "Past-due notice",
      status: PRODUCT_STATUS.not_started,
      stageKey: "completed",
      link: ROUTE_PATH["PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY"],
      fields: [
        {
          label: "What is the notice name?",
          key: "noticeName",
          value: "",
        },
        {
          label: "How many days past due does the notice have to be sent?",
          key: "pastDueDays",
          value: "",
        },
      ],
    },
  ],
};

const DEPOSIT_COMPONENT_CLASS_FOR_SUMMARY: {
  [key: string]: ProductSummaryType;
} = {
  componentFee: deepCopy(SUMMARY_FEE),
  componentInt: deepCopy(SUMMARY_INTEREST),
  componentLimit: deepCopy(SUMMARY_LIMIT),
  componentReward: deepCopy(SUMMARY_REWARD),
  componentNsf: deepCopy(SUMMARY_NSF),
  componentRepay: deepCopy({ ...SUMMARY_REPAY, name: "Repayment (deposit)" }),
  componentCollateral: deepCopy({
    ...SUMMARY_COLLATERAL,
    name: "Collateral (deposit)",
  }),
  componentTd: deepCopy(SUMMARY_TERM),
  componentDepChrgOff: deepCopy(SUMMARY_CHARGE_OF),
  componentTrnOpt: deepCopy(SUMMARY_TRANSACTION),
  componentRules: deepCopy(SUMMARY_RULES),
};

const LOAN_COMPONENT_CLASS_FOR_SUMMARY: { [key: string]: ProductSummaryType } =
  {
    componentFee: deepCopy({
      ...SUMMARY_FEE,
      name: "Fee component (loan)",
      link: ROUTE_PATH["FEE_GENERAL_SETUP_LOAN"],
    }),
    componentInt: deepCopy({
      ...SUMMARY_INTEREST,
      name: "Interest component (loan)",
      link: ROUTE_PATH["INTEREST_LOAN_GENERAL_FEATURE_FORM"],
    }),
    componentLimit: deepCopy({ ...SUMMARY_LIMIT, name: "Limit (loan)" }),
    componentReward: deepCopy({ ...SUMMARY_REWARD, name: "Rewards (loan)" }),
    componentNsf: deepCopy({ ...SUMMARY_NSF, name: "NSF component (loan)" }),
    componentRepay: deepCopy(SUMMARY_REPAY),
    componentCollateral: deepCopy(SUMMARY_COLLATERAL),
    componentTd: deepCopy({ ...SUMMARY_TERM, name: "Term component (loan)" }),
    componentDepChrgOff: deepCopy({
      ...SUMMARY_CHARGE_OF,
      name: "Charge-off component (loan)",
    }),
    componentTrnOpt: deepCopy({
      ...SUMMARY_TRANSACTION,
      name: "Transaction component (loan)",
    }),
    componentRules: deepCopy({
      ...SUMMARY_RULES,
      name: "Rule component (loan)",
    }),
  };

const PRODUCT_SUMMARY_STEPS: {
  [key: string]: ProductSummaryFeatureDetailSteps[];
} = {
  componentFee: deepCopy(SUMMARY_FEE_STEPS),
  componentInt: deepCopy(SUMMARY_INTEREST_STEPS),
  componentLimit: deepCopy(SUMMARY_LIMIT_STEPS),
  componentReward: deepCopy(SUMMARY_REWARD_STEPS),
  componentNsf: deepCopy(SUMMARY_NSF_STEPS),
  componentRepay: deepCopy(SUMMARY_REPAY_STEPS),
  componentCollateral: deepCopy(SUMMARY_COLLATERAL_STEPS),
  componentTd: deepCopy(SUMMARY_TERM_STEPS),
  componentDepChrgOff: deepCopy(SUMMARY_CHARGE_OF_STEPS),
  componentTrnOpt: deepCopy(SUMMARY_TRANSACTION_STEPS),
  componentRules: deepCopy(SUMMARY_RULES_STEPS),
};

const PRODUCT_SUMMARY_STEPS_LOAN: {
  [key: string]: ProductSummaryFeatureDetailSteps[];
} = {
  componentFee: deepCopy(SUMMARY_FEE_STEPS_LOAN),
  componentInt: deepCopy(SUMMARY_INTEREST_STEPS_LOAN),
  componentLimit: deepCopy(SUMMARY_LIMIT_STEPS),
  componentReward: deepCopy(SUMMARY_REWARD_STEPS),
  componentNsf: deepCopy(SUMMARY_NSF_STEPS),
  componentRepay: deepCopy(SUMMARY_REPAY_STEPS),
  componentCollateral: deepCopy(SUMMARY_COLLATERAL_STEPS),
  componentTd: deepCopy(SUMMARY_TERM_STEPS),
  componentDepChrgOff: deepCopy(SUMMARY_CHARGE_OF_STEPS),
  componentTrnOpt: deepCopy(SUMMARY_TRANSACTION_STEPS),
  componentRules: deepCopy(SUMMARY_RULES_STEPS),
};

const ADDITIONAL_COMPONENTS_LOAN_MAPPING: {
  [key: string]: AdditionalComponentType[];
} = {
  personal: [
    {
      componentName: "Fee component (loan)",
      componentClass: COMPONENT_CLASS.fee,
    },
    {
      componentName: "Interest component (loan)",
      componentClass: COMPONENT_CLASS.interest,
    },
    {
      componentName: "Repayment component (loan)",
      componentClass: COMPONENT_CLASS.repay,
    },
    {
      componentName: "Collateral component (loan)",
      componentClass: COMPONENT_CLASS.collateral,
    },
    {
      componentName: "Rule component (loan)",
      componentClass: COMPONENT_CLASS.rules,
    },
  ],
  business: [
    {
      componentName: "Fee component (loan)",
      componentClass: COMPONENT_CLASS.fee,
    },
    {
      componentName: "Interest component (loan)",
      componentClass: COMPONENT_CLASS.interest,
    },
    {
      componentName: "Repayment component (loan)",
      componentClass: COMPONENT_CLASS.repay,
    },
    {
      componentName: "Collateral component (loan)",
      componentClass: COMPONENT_CLASS.collateral,
    },
    {
      componentName: "Rule component (loan)",
      componentClass: COMPONENT_CLASS.rules,
    },
  ],
};

const ADDITIONAL_TEMPLATED_FEATURES_DEPOSIT_MAPPING: {
  [key: string]: AdditionalComponentType[];
} = {
  personal: [
    {
      componentName: "Fee component (deposit)",
      componentClass: COMPONENT_CLASS["fee"],
    },
    {
      componentName: "Interest component (deposit)",
      componentClass: COMPONENT_CLASS["interest"],
    },
    {
      componentName: "Limit component (deposit)",
      componentClass: COMPONENT_CLASS["limit"],
    },
    {
      componentName: "Rewards component (deposit)",
      componentClass: COMPONENT_CLASS["reward"],
    },
    {
      componentName: "NSF component (deposit)",
      componentClass: COMPONENT_CLASS["nsf"],
    },

    {
      componentName: "Transaction component (deposit)",
      componentClass: COMPONENT_CLASS["transactionDeposit"],
    },
    {
      componentName: "Charge Off component (deposit)",
      componentClass: COMPONENT_CLASS["chargeOff"],
    },
    {
      componentName: "Term component (deposit)",
      componentClass: COMPONENT_CLASS["term"],
    },
    {
      componentName: "Rule component (deposit)",
      componentClass: COMPONENT_CLASS["rules"],
    },
  ],
  business: [
    {
      componentName: "Fee component (deposit)",
      componentClass: COMPONENT_CLASS["fee"],
    },
    {
      componentName: "Interest component (deposit)",
      componentClass: COMPONENT_CLASS["interest"],
    },
    {
      componentName: "Limit component (deposit)",
      componentClass: COMPONENT_CLASS["limit"],
    },
    {
      componentName: "Rewards component (deposit)",
      componentClass: COMPONENT_CLASS["reward"],
    },
    {
      componentName: "NSF component (deposit)",
      componentClass: COMPONENT_CLASS["nsf"],
    },

    {
      componentName: "Transaction component (deposit)",
      componentClass: COMPONENT_CLASS["transactionDeposit"],
    },
    {
      componentName: "Charge Off component (deposit)",
      componentClass: COMPONENT_CLASS["chargeOff"],
    },
    {
      componentName: "Term component (deposit)",
      componentClass: COMPONENT_CLASS["term"],
    },
    {
      componentName: "Rule component (deposit)",
      componentClass: COMPONENT_CLASS["rules"],
    },
  ],
};

export function getCompleteAttributeList(
  ifxAcctType: string
): ProductSummaryType[] {
  return IFX_ACCT_PRODUCT_TYPE[ifxAcctType] === IFX_PRODUCT_TYPE_VAL.loan
    ? [
        PRODUCT_BASICS_ATTRIBUTES,
        CADENCES_FORM,
        ELIGIBILITY_AND_COMPONENTS_CONFIGURATION,
        REGULATORY_ADHERENCE_LIMITATIONS_PRODUCT_ATTRIBUTE[ifxAcctType ?? ""],
        LOAN_DELIQUENCY,
      ]
    : [
        PRODUCT_BASICS_ATTRIBUTES,
        CADENCES_FORM,
        ADDITIONAL_PRODUCT_DETAILS_FOR_DEPOSIT,
        REGULATORY_ADHERENCE_LIMITATIONS_PRODUCT_ATTRIBUTE[ifxAcctType ?? ""],
      ];
}

export function getCurrentProductFormLinkToNavigate(
  product: ProductType,
  workflowProductList: ProductWorkflowModelType[]
) {
  const href = `${ROUTE_PATH.PRODUCT_SUMMARY_FULLPATH}?productName=${product.name}`;
  if (product?.status !== DASHBOARD_ACCORDION_STATUS.completed) {
    const componentToBeNavigated = product.components?.find(
      (component) =>
        !workflowProductList.some(
          (wrkFLow) =>
            wrkFLow.stage === component.componentClass &&
            wrkFLow.status === DASHBOARD_ACCORDION_STATUS.completed
        )
    );
    if (componentToBeNavigated) {
      return getFormLinkOfCurrentComponent(
        product,
        workflowProductList,
        componentToBeNavigated.componentClass
      );
    } else {
      return getFormLinkOfAdditionalProductAttibutes(
        product,
        workflowProductList
      );
    }
  } else {
    return href;
  }
}

function getFormLinkOfCurrentComponent(
  product: ProductType,
  workflowProductList: ProductWorkflowModelType[],
  componentToBeNavigated: string
) {
  let href = `${ROUTE_PATH.PRODUCT_SUMMARY_FULLPATH}?productName=${product.name}`;
  const selectedWorkflowProduct = workflowProductList.find(
    (workflowProduct) =>
      workflowProduct.modelKey === product.name &&
      workflowProduct.stage === componentToBeNavigated
  );

  const featureMainMenu: any =
    IFX_ACCT_PRODUCT_TYPE[product?.ifxAcctType!] === IFX_PRODUCT_TYPE_VAL.loan
      ? deepCopy(LOAN_COMPONENT_CLASS_FOR_SIDEBAR)
      : deepCopy(DEPOSIT_COMPONENT_CLASS_FOR_SIDEBAR);

  const featureSubMenuList =
    featureMainMenu?.[componentToBeNavigated]?.subMenuList;

  if (!featureSubMenuList && featureMainMenu) {
    const componentLinkToNavigate =
      featureMainMenu?.[componentToBeNavigated]?.href;
    return `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${componentLinkToNavigate}?productName=${product.name}`;
  }

  let componentFormLinkToNavigate;
  if (
    selectedWorkflowProduct?.status === DASHBOARD_ACCORDION_STATUS.notStarted
  ) {
    componentFormLinkToNavigate = featureSubMenuList?.[0].href;
    href = `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${componentFormLinkToNavigate}?productName=${product.name}`;
    return href;
  } else {
    const index = featureSubMenuList?.findIndex(
      (subMenu: any) => subMenu.stageStatus === selectedWorkflowProduct?.status
    );
    if (index !== undefined && index > -1) {
      componentFormLinkToNavigate = featureSubMenuList?.[index + 1].href;
      href = `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${componentFormLinkToNavigate}?productName=${product.name}`;
    }
    return href;
  }
}

function getFormLinkOfAdditionalProductAttibutes(
  product: ProductType,
  workflowProductList: ProductWorkflowModelType[]
) {
  let href = `${ROUTE_PATH.PRODUCT_SUMMARY_FULLPATH}?productName=${product.name}`;
  let componentFormLinkToNavigate;
  const productAttributeData = deepCopy(
    getCompleteAttributeList(product?.ifxAcctType ?? "")
  );
  const index = productAttributeData.findIndex((attribute) => {
    return workflowProductList.find(
      (workflowProduct) =>
        workflowProduct?.modelKey === product.name &&
        attribute?.componentClass === workflowProduct.stage &&
        workflowProduct?.status !== DASHBOARD_ACCORDION_STATUS.completed
    );
  });
  if (index > -1) {
    componentFormLinkToNavigate = productAttributeData[index].link;
    href = `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${componentFormLinkToNavigate}?productName=${product.name}`;
  }
  return href;
}

//exporting Mappings
export {
  SUMMARY_PRODUCT_ATTRIBUTES,
  PRODUCT_SUMMARY_STEPS,
  DEPOSIT_COMPONENT_CLASS_FOR_SUMMARY,
  LOAN_COMPONENT_CLASS_FOR_SUMMARY,
  REGULATORY_ADHERENCE_LIMITATIONS_PRODUCT_ATTRIBUTE,
  CADENCES_FORM,
  PRODUCT_BASICS_ATTRIBUTES,
  ADDITIONAL_PRODUCT_DETAILS_FOR_DEPOSIT,
  ADDITIONAL_TEMPLATED_FEATURES_DEPOSIT_MAPPING,
  ELIGIBILITY_AND_COMPONENTS_CONFIGURATION,
  ADDITIONAL_COMPONENTS_LOAN_MAPPING as ADDITIONAL_TEMPLATED_FEATURES_LOAN_MAPPING,
  PRODUCT_SUMMARY_STEPS_LOAN,
  LOAN_DELIQUENCY,
};
