"use client";
import { ComponentType, useContext, useMemo } from "react";
import "./SelectDropdown.scss";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import {
  Box,
  Flex,
  Grid,
  Link,
  Tag,
  TagLabel,
  TagRightIcon,
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { IFX_ACCT_PRODUCT_TYPE } from "@/utils/constants";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  ControlProps,
  GroupBase,
  MenuListProps,
  MenuProps,
  OptionProps,
  Select,
  SingleValueProps,
  components,
} from "chakra-react-select";
import cx from "classnames";
import { IconForward } from "public/assets";
import Image from "next/image";
import { OptionWithDesc, generateViewUrl } from "./dropdown-util";
import { isConsolePages, checkReadOnlyEntitlement } from "@/utils/common";
import { Option } from "@/utils/types";

export interface SingleSelectDropdownProps<T> {
  id?: string;
  value: T;
  valueType?: "object";
  onChange: (value: T) => void;
  onBlur?: (event: React.FocusEvent) => void;
  dropdownList: any[];
  placeholder?: string;
  customClass?: string;
  disable?: any;
  clearable?: boolean;
  modelViewUrl?: string;
  withDescription?: boolean;
  isMulti?: boolean;
  hideDashedBorder?: boolean;
  descHeader?: string;
  error?: boolean;
  sortDropdown?: boolean;
}

export default function SelectDropdown<T>(props: SingleSelectDropdownProps<T>) {
  const {
    id,
    value,
    valueType,
    onChange,
    onBlur,
    placeholder = "Select",
    dropdownList,
    customClass,
    disable,
    clearable = true,
    isMulti,
    withDescription,
    modelViewUrl,
    hideDashedBorder,
    descHeader,
    error,
    sortDropdown = true,
  } = props;

  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const isConsolePage = isConsolePages();
  const { productDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);

  const _handleChange = (selectedItem: any) => {
    if (valueType === "object") {
      onChange(selectedItem);
    } else {
      // If option is an object return the value, else return the option item (string)
      const newValue = Array.isArray(selectedItem)
        ? selectedItem.map((item) => item.value)
        : selectedItem.value;
      onChange(newValue);
    }
  };

  const selectedValue = useMemo(() => {
    if (valueType === "object") {
      return value;
    }
    if (value != null && value !== "" && value !== -1) {
      if (props.isMulti && Array.isArray(value)) {
        if (value.length) {
          if (
            typeof dropdownList[0] === "string" ||
            typeof dropdownList[0] === "number"
          ) {
            return dropdownList
              .filter((item) => value.includes(item))
              .map((opt) => ({ label: opt, value: opt }));
          }
          return dropdownList.filter((item) => value.includes(item.value));
        }
        return null;
      }
      // If option is string|number[]
      if (
        typeof dropdownList[0] === "string" ||
        typeof dropdownList[0] === "number"
      ) {
        return { label: value, value: value };
      } else {
        // If option is an object
        return [dropdownList.find((item) => item.value === value) || {}];
      }
    }
    return null;
  }, [value, props.isMulti]);

  // Create options for react-select
  const options = useMemo(() => {
    // Sort alphabetically
    const sortFn = (a: Option<string>, b: Option<string>) => {
      // If option labels are numbers convert them to number and then sort
      if (!isNaN(Number(a.label)) && !isNaN(Number(a.label))) {
        return Number(a.label) < Number(b.label) ? -1 : 1;
      }
      return a.label < b.label ? -1 : 1;
    };
    if (
      typeof dropdownList[0] === "string" ||
      typeof dropdownList[0] === "number"
    ) {
      const options = dropdownList.map((option) => ({
        label: option,
        value: option,
      }));
      return sortDropdown ? options.sort(sortFn) : options;
    }
    return sortDropdown ? dropdownList.sort(sortFn) : dropdownList;
  }, [dropdownList, sortDropdown]);

  // Options map used to display selected value with description
  const optionsMap = useMemo(() => {
    return options.reduce<Record<string, string>>((acc, opt) => {
      acc[opt.value] = withDescription
        ? `${opt.label} - ${opt.desc}`
        : opt.label;
      return acc;
    }, {});
  }, [options]);

  const clearSelection = () => {
    onChange((isMulti ? [] : "") as T);
  };

  const onRemoveItem = (removedItem: any) => {
    if (Array.isArray(value)) {
      const updatedList = value.filter((item) => {
        return item.value ? item.value !== removedItem : item !== removedItem;
      });
      onChange(updatedList as T);
    }
  };

  return (
    <>
      <div className={cx("fx-select-container", customClass)}>
        <Select
          inputId={id}
          value={selectedValue as any}
          options={options}
          onBlur={onBlur}
          onChange={_handleChange}
          useBasicStyles
          placeholder={placeholder}
          closeMenuOnSelect={!isMulti}
          isMulti={isMulti}
          isDisabled={
            (envIsDisabled && isConsolePage) || disable || checkEntitlement
          }
          controlShouldRenderValue={!isMulti}
          hideSelectedOptions={false}
          components={{
            ...(withDescription
              ? {
                  SingleValue: CustomSingleValue,
                }
              : {}),

            MenuList: CustomMenuList,

            Control: CustomControl,

            Option: CustomOption,

            Menu: CustomMenu,
            ClearIndicator: () => null,
          }}
          classNames={{
            control: (state) =>
              cx({
                "select-control": true,
                "menu-open": state.menuIsOpen,
                error,
              }),
            option: (state) =>
              cx({
                "select-option": true,
                selected: state.isSelected,
                focused: state.isFocused,
              }),
            menuList: () =>
              cx({
                "menu-list": true,
                "multi-select": isMulti,
                "with-description": withDescription,
              }),
            menu: () => "menu",
            noOptionsMessage: () => "no-option-message",
          }}
          // @ts-ignore These props are used in the custom components as part of `selectProps`
          withDescription={withDescription}
          modelViewUrl={modelViewUrl}
          selectedValue={selectedValue}
          clearSelection={clearSelection}
          descHeader={descHeader}
        />
        {!isMulti && clearable && selectedValue && (
          <span className="clear" onClick={clearSelection}>
            Clear
          </span>
        )}
      </div>

      {/* Show selected values for multi select */}
      {isMulti && (
        <div className="fx-selected-values-container">
          <div className={!hideDashedBorder ? "dashed-left-border" : ""}>
            {Array.isArray(value) && value.length ? (
              <Box className="selected-options">
                <Flex className="tag-container">
                  {value.map((val) => (
                    <Tag
                      className="app-tags app-tag-selected"
                      variant="solid"
                      key={(val.value ?? val) as string}
                    >
                      <TagLabel>
                        {(val.label ?? optionsMap[val]) as string}
                      </TagLabel>
                      <TagRightIcon
                        onClick={() => onRemoveItem(val.value ?? val)}
                        as={SmallCloseIcon}
                      />
                    </Tag>
                  ))}
                </Flex>
                <span className="clear" onClick={clearSelection}>
                  Clear
                </span>
              </Box>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
}

// Custom components to pass into chakra-react-select
const ViewLink = ({
  data,
  modelViewUrl,
}: {
  data: OptionWithDesc;
  modelViewUrl: string;
}) => {
  return (
    <>
      <Link
        className="view-link"
        href={`${generateViewUrl(modelViewUrl!, data)}`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        View
        <Image src={IconForward} style={{ height: "12px" }} alt="icon-view" />
      </Link>
    </>
  );
};

const CustomSingleValue = (
  componentProps: SingleValueProps<OptionWithDesc>
) => {
  return (
    <components.SingleValue {...componentProps}>
      {componentProps.children} - {componentProps.data.desc}
    </components.SingleValue>
  );
};

const CustomControl: ComponentType<
  ControlProps<OptionWithDesc, boolean, GroupBase<OptionWithDesc>>
> = (componentProps) => {
  return <components.Control {...componentProps} />;
};

const CustomMenu: ComponentType<
  MenuProps<OptionWithDesc, boolean, GroupBase<OptionWithDesc>>
> = (componentProps) => {
  // @ts-ignore
  // prettier-ignore
  const { selectedValue, clearSelection, withDescription, descHeader, modelViewUrl } = componentProps.selectProps;
  return (
    <components.Menu {...componentProps}>
      {withDescription && (
        <Box className="menu-header">
          <Box as="span" flex="0 0 40%">
            Label
          </Box>
          <Box as="span" flex="0 1 60%">
            {descHeader ?? "Description"}
          </Box>
          {/* Dummy column */}
          {modelViewUrl && <Box flex="0 0 10%" />}
        </Box>
      )}
      {componentProps.children}

      {componentProps.isMulti ? (
        <Box className="clear-all-container">
          {selectedValue && (
            <Box cursor="pointer" onClick={clearSelection}>
              Clear All
            </Box>
          )}
        </Box>
      ) : null}
    </components.Menu>
  );
};

const CustomMenuList: ComponentType<
  MenuListProps<OptionWithDesc, boolean, GroupBase<OptionWithDesc>>
> = (componentProps) => {
  return (
    <components.MenuList {...componentProps}>
      {componentProps.children}
    </components.MenuList>
  );
};

const CustomOption = ({
  children,
  ...componentProps
}: OptionProps<OptionWithDesc>) => {
  // @ts-ignore
  const { withDescription, modelViewUrl } = componentProps.selectProps;
  return (
    <components.Option {...componentProps}>
      {withDescription ? (
        <>
          <Flex flex="0 0 40%" gap={4}>
            {componentProps.isMulti && (
              <input
                type="checkbox"
                checked={componentProps.isSelected}
                onChange={() => null}
              />
            )}
            {componentProps.label}
          </Flex>
          <Box
            as="span"
            flex="0 1 60%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            title={componentProps.data.desc}
          >
            {componentProps.data.desc}
          </Box>
          {modelViewUrl && (
            <Box flex="0 0 10%">
              <ViewLink
                data={componentProps.data}
                modelViewUrl={modelViewUrl}
              />
            </Box>
          )}
        </>
      ) : (
        <Grid
          gridTemplateColumns={
            componentProps.isMulti ? "auto 1fr auto" : "1fr auto"
          }
          gridGap={4}
          width="100%"
        >
          {componentProps.isMulti && (
            <input
              type="checkbox"
              checked={componentProps.isSelected}
              onChange={() => null}
            />
          )}
          {componentProps.label}
          {modelViewUrl && (
            <Flex flexBasis="50%" gap={2} alignItems="center">
              <ViewLink
                data={componentProps.data}
                modelViewUrl={modelViewUrl}
              />
            </Flex>
          )}
        </Grid>
      )}
    </components.Option>
  );
};
