import { ProductComponentType } from "@/components/product-management";
import { ROUTE_PATH } from "@/route-config/route-path";
import { ValueOf } from "./types";

export enum EnvAllowConfigChanges {
  no = "no",
  yes = "yes",
  "with-warning" = "with-warning",
}

export const OPTIONS_API_FIELD_FORMAT = {
  duraiton: "duration",
  frequency: "frequency",
  uri: "uri",
};

export const ALGOLIA_KEYS = {
  indexName: "dev_shared_fast",
  indexNameSandbox: "dev_shared_fast_611bak",
};

export const OPTIONS_API_FIELD_TYPE = {
  object: "object",
  array: "array",
};

export const DUPLICATE_CONFIG = "is_duplicate";
export const MAIN_FORM_ID = "finxact-form";
export const ENV_ID_KEY = "environment-id";
export const NEW_IC_ENTITY_ID = "-1";

//maxtrix
export const MATRIX_NAME = "prod_bkCreateCmpnts";
export const VALID_FROM_DTM = "2000-01-01T00:00:00Z";
export const MATRIX_TYPE_DESCRIPTION =
  "Used by FAST platform for tracking components selected during product creation workflow.";
export const MATRIX_TYPE_PROP_LABEL = "Product Type";
export const MATRIX_TYPE_PROP_NAME = "prod_bk.prodType";
export const MATRIX_TYPE_PROP_TYPE = "string";
export const MATRIX_TYPE_PROP_FMT = "string";
export const MATRIX_TYPE_PROP_WHEN_BTWN = 3;
export const MATRIX_VAL_TYPE = "string";

//access management
export const PROFILE_TEMPLATE_KEY: string = "profileTemplate";
export const ROLE_TYPE_KEY: string = "roleType";
export const NAME_KEY: string = "name";
export const EMAIL_KEY: string = "email";
export const ROLE_NAME_KEY: string = "roleName";
export const ORGANIZATION_KEY: string = "organization";
export const STATUS_KEY: string = "status";
export const APPLICATION_KEY: string = "type";
export const TEAM_STATUS_KEY: string = "Status";

export const TAB_NAMES = {
  team: "team",
  profileTemplates: "profile_templates",
  roles: "roles",
};

export const TABS = [
  TAB_NAMES.team,
  TAB_NAMES.profileTemplates,
  TAB_NAMES.roles,
];
//For Reward component
export const PAYOUT_OPTION_PROGRAM_TYPE = {
  adjustInterestAccrued: {
    value: 1,
    label:
      "Adjust interest accrued if deposit amount is met during deposit interval",
  },
  adjustInterestRate: {
    value: 5,
    label: "Adjust interest rate at accrual if qualifying",
  },
  addBonusRate: {
    value: 7,
    label: "Add bonus rate at rollover",
  },
};

export const REWARD_CONFIGURATION_DATA = {
  savingsGoalReward: "Savings goal reward",
  relationshipReward: "Relationship reward",
  rolloverReward: "Rollover reward",
  transactionReward: "Transaction reward",
};

export const REWARD_CONFIG_RADIO_BTN_DATA = [
  {
    label: REWARD_CONFIGURATION_DATA.savingsGoalReward,
    value: REWARD_CONFIGURATION_DATA.savingsGoalReward,
  },
  {
    label: REWARD_CONFIGURATION_DATA.relationshipReward,
    value: REWARD_CONFIGURATION_DATA.relationshipReward,
  },
  {
    label: REWARD_CONFIGURATION_DATA.rolloverReward,
    value: REWARD_CONFIGURATION_DATA.rolloverReward,
  },
  {
    label: REWARD_CONFIGURATION_DATA.transactionReward,
    value: REWARD_CONFIGURATION_DATA.transactionReward,
  },
];

export const ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS = {
  productBasics: "productBasics",
  cadences: "cadences",
  eligibilityAndComponentsConfiguration:
    "eligibilityAndComponentsConfiguration",
  additionalProductFeatures: "additionalProductFeatures",
  regulatoryAdherenceLimitations: "regulatoryAdherenceLimitations",
  loanDelinquency: "loanDelinquency",
};

export const ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS_LIST = [
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.productBasics,
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.cadences,
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.eligibilityAndComponentsConfiguration,
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.additionalProductFeatures,
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.regulatoryAdherenceLimitations,
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS.loanDelinquency,
];

export const COMPONENT_CLASS = {
  fee: "componentFee",
  interest: "componentInt",
  limit: "componentLimit",
  nsf: "componentNsf",
  chargeOff: "componentDepChrgOff",
  term: "componentTd",
  collateral: "componentCollateral",
  transactionDeposit: "componentTrnOpt",
  reward: "componentReward",
  repay: "componentRepay",
  rules: "componentRules",
};

export const PRODUCT_CLASS_NAME: { [key: string]: string } = {
  componentFee: "Fee",
  componentInt: "Interest",
  componentReward: "Reward",
  componentLimit: "Limit",
  componentNsf: "NSF",
  componentRepay: "Repayment",
  componentCollateral: "Collateral",
  componentTd: "Term",
  componentDepChrgOff: "Chargeoff",
  componentTrnOpt: "Transaction",
  componentRules: "Rule",
};

export const PRODUCT_CLASS_NAME_WITH_COMPONENT: { [key: string]: string } = {
  componentFee: "Fee component",
  componentInt: "Interest component",
  componentReward: "Reward component",
  componentLimit: "Limit component",
  componentNsf: "NSF component",
  componentRepay: "Repayment component",
  componentCollateral: "Collateral component",
  componentTd: "Term component",
  componentDepChrgOff: "Chargeoff component",
  componentTrnOpt: "Transaction component",
  componentRules: "Rule component",
};

export enum IFX_ACCT_TYPE {
  CCA = "CCA",
  CDA = "CDA",
  CLA = "CLA",
  CMA = "CMA",
  DDA = "DDA",
  EQU = "EQU",
  GL = "GL",
  ILA = "ILA",
  LOC = "LOC",
  MLA = "MLA",
  MMA = "MMA",
  SDA = "SDA",
}

export const FAST_SUPPORTED_IFX_ACCT_TYPES = [
  IFX_ACCT_TYPE.SDA,
  IFX_ACCT_TYPE.CDA,
  IFX_ACCT_TYPE.DDA,
  IFX_ACCT_TYPE.ILA,
  IFX_ACCT_TYPE.LOC,
]

export enum IFX_PRODUCT_TYPE_VAL {
  loan = "Loan",
  deposit = "Deposit",
}

export enum IFX_PRODUCT_CLASS_VAL {
  loan = "posn_ln",
  deposit = "posn_dep",
}

export enum IFX_PRODUCT_SUB_TYPE_VAL {
  personal = "Personal",
  business = "Business",
}

export enum IFX_GL_CATEGORY_VAL {
  asset = 1,
  liability,
  capital,
  revenue,
  expense,
}
export const CADENCES_FORM_SECTIONS = {
  STATEMENT_CADENCE: "Statement cadence",
  INTERIM_UPDATES: "Interim Updates",
  INACTIVITY_PROTOCOLS: "Inactivity Protocols",
  DORMACY_MANAGEMENT: "Dormancy Management",
};
export type IfxAcctProductType =
  | IFX_PRODUCT_TYPE_VAL.loan
  | IFX_PRODUCT_TYPE_VAL.deposit
  | null;

export type IfxAcctProductSubType =
  | IFX_PRODUCT_SUB_TYPE_VAL.personal
  | IFX_PRODUCT_SUB_TYPE_VAL.business
  | null;

export const IFX_ACCOUNT_MAPPING: Record<IFX_ACCT_TYPE, string> = {
  [IFX_ACCT_TYPE.CCA]: "Credit card account",
  [IFX_ACCT_TYPE.CDA]: "Certificate of deposit",
  [IFX_ACCT_TYPE.CLA]: "Commercial loan account",
  [IFX_ACCT_TYPE.CMA]: "Cash management account",
  [IFX_ACCT_TYPE.DDA]: "Demand deposit account",
  [IFX_ACCT_TYPE.EQU]: "Home equity loan",
  [IFX_ACCT_TYPE.GL]: "General Ledger",
  [IFX_ACCT_TYPE.ILA]: "Installment loan account",
  [IFX_ACCT_TYPE.LOC]: "Consumer line of credit",
  [IFX_ACCT_TYPE.MLA]: "Mortgage loan account",
  [IFX_ACCT_TYPE.MMA]: "Money market account",
  [IFX_ACCT_TYPE.SDA]: "Savings deposit account",
};

export const IFX_POSN_CLASS_TYPE: Record<string, any> = {
  [IFX_PRODUCT_TYPE_VAL.loan]: IFX_PRODUCT_CLASS_VAL.loan,
  [IFX_PRODUCT_TYPE_VAL.deposit]: IFX_PRODUCT_CLASS_VAL.deposit,
};

export const IFX_ACCT_PRODUCT_TYPE: Record<string, IfxAcctProductType> = {
  [IFX_ACCT_TYPE.CCA]: IFX_PRODUCT_TYPE_VAL.loan,
  [IFX_ACCT_TYPE.CDA]: IFX_PRODUCT_TYPE_VAL.deposit,
  [IFX_ACCT_TYPE.CLA]: IFX_PRODUCT_TYPE_VAL.loan,
  [IFX_ACCT_TYPE.CMA]: IFX_PRODUCT_TYPE_VAL.deposit,
  [IFX_ACCT_TYPE.DDA]: IFX_PRODUCT_TYPE_VAL.deposit,
  [IFX_ACCT_TYPE.EQU]: IFX_PRODUCT_TYPE_VAL.loan,
  [IFX_ACCT_TYPE.GL]: null,
  [IFX_ACCT_TYPE.ILA]: IFX_PRODUCT_TYPE_VAL.loan,
  [IFX_ACCT_TYPE.LOC]: IFX_PRODUCT_TYPE_VAL.loan,
  [IFX_ACCT_TYPE.MLA]: IFX_PRODUCT_TYPE_VAL.loan,
  [IFX_ACCT_TYPE.MMA]: IFX_PRODUCT_TYPE_VAL.deposit,
  [IFX_ACCT_TYPE.SDA]: IFX_PRODUCT_TYPE_VAL.deposit,
};

export const IFX_ACCT_GL_CATEGORY: Record<string, number> = {
  [IFX_ACCT_TYPE.CCA]: IFX_GL_CATEGORY_VAL.asset,
  [IFX_ACCT_TYPE.CDA]: IFX_GL_CATEGORY_VAL.liability,
  [IFX_ACCT_TYPE.CLA]: IFX_GL_CATEGORY_VAL.asset,
  [IFX_ACCT_TYPE.CMA]: IFX_GL_CATEGORY_VAL.liability,
  [IFX_ACCT_TYPE.DDA]: IFX_GL_CATEGORY_VAL.liability,
  [IFX_ACCT_TYPE.EQU]: IFX_GL_CATEGORY_VAL.asset,
  [IFX_ACCT_TYPE.GL]: IFX_GL_CATEGORY_VAL.liability,
  [IFX_ACCT_TYPE.ILA]: IFX_GL_CATEGORY_VAL.asset,
  [IFX_ACCT_TYPE.LOC]: IFX_GL_CATEGORY_VAL.asset,
  [IFX_ACCT_TYPE.MLA]: IFX_GL_CATEGORY_VAL.asset,
  [IFX_ACCT_TYPE.MMA]: IFX_GL_CATEGORY_VAL.liability,
  [IFX_ACCT_TYPE.SDA]: IFX_GL_CATEGORY_VAL.liability,
};

export const RECOMENDED_PRODUCT_FEATURES: {
  [key: string]: ProductComponentType[];
} = {
  [IFX_ACCT_TYPE.SDA]: [
    { componentClass: "componentFee" },
    { componentClass: "componentInt" },
    { componentClass: "componentLimit" },
    { componentClass: "componentReward" },
  ],
  [IFX_ACCT_TYPE.DDA]: [
    { componentClass: "componentNsf" },
    { componentClass: "componentTrnOpt" },
    { componentClass: "componentDepChrgOff" },
    { componentClass: "componentFee" },
    { componentClass: "componentInt" },
    { componentClass: "componentLimit" },
  ],
  [IFX_ACCT_TYPE.CDA]: [
    // Will uncomment term component once FDT-503 is done.
    { componentClass: "componentTd" },
    { componentClass: "componentFee" },
    { componentClass: "componentInt" },
    { componentClass: "componentLimit" },
  ],
  [IFX_ACCT_TYPE.LOC]: [
    { componentClass: "componentRepay" },
    { componentClass: "componentInt" },
    { componentClass: "componentFee" },
  ],
  [IFX_ACCT_TYPE.ILA]: [
    { componentClass: "componentInt" },
    { componentClass: "componentRepay" },
    { componentClass: "componentFee" },
  ],
};

export const PRODUCT_IFX_TYPE_TITLE: Record<string, string> = {
  [IFX_ACCT_TYPE.SDA]: "Savings",
  [IFX_ACCT_TYPE.CDA]: "Certificate of Deposit",
  [IFX_ACCT_TYPE.DDA]: "Transactional (Checking)",
  [IFX_ACCT_TYPE.ILA]: "Installment",
  [IFX_ACCT_TYPE.LOC]: "Revolving Lines of Credit",
};

export const POSITION_CLASS = {
  posn_dep: "Bank Deposit Account Position",
  posn_ln: "Bank Loan Account Position",
};

export const PROD_SUMM_MANUALLY_ADDED_KEY_COMPONENT = [
  "Single Transaction Limit Configuration",
  "Accumulated Transaction Limit Configuration",
  "Fee configuration",
  "Due item configuration",
  "Rule groups",
];

export const PROD_SMMRY_LIST_ITEM_ZERO_STATE: Record<string, string> = {
  "Single Transaction Limit Configuration": "No single transaction limits",
  "Accumulated Transaction Limit Configuration":
    "No accumulated transaction limits",
  "Fee configuration": "No fee items",
  "Due item configuration": "No due items",
  "Rule groups": "No rule groups",
};

export type APP_STATUS = "completed" | "not_started" | "in_progress";

export const DASHBOARD_ACCORDION_STATUS: Record<
  "notStarted" | "inProgress" | "completed",
  "not_started" | "in_progress" | "completed"
> = {
  completed: "completed",
  notStarted: "not_started",
  inProgress: "in_progress",
};

export const DASHBOARD_JOURNEY = {
  exploreFinxact: "Explore Finxact",
  jumpstartActivation: "Jumpstart activation",
  foundationalSetup: "Foundational setup",
  productConfiguration: "Product configuration",
  testAndDeploy: "Test & deploy",
};

export const DASHBOARD_MODEL_KEY = {
  exploreFinxact: "explore_finxact",
  jumpstartActivation: "jumpstart_activation",
  foundationalSetup: "foundational_setup",
  productConfiguration: "product_configuration",
  testAndDeploy: "test_and_deploy",
};
export const PLATFORM_SETUP_MODEL_KEY: { [key: string]: string } = {
  privateLink: "private_link",
  natIps: "nat_ips",
  listofIps: "list_of_ips",
};
export const PLATFORM_SETUP_STATUS: { [key: string]: string } = {
  completed: "completed",
  incomplete: "incomplete",
};
export const EXPLORE_FINXACT_STEPS_NAME = {
  sandboxOverview: "sandbox_overview",
  sandbox: "sandbox",
  enterpriseLicense: "enterprise_license",
};

export const JUMPSTART_ACTIVATION_STEPS_NAME = {
  platformSetup: "platform_setup",
  accessManagement: "access_management",
  visitAcademy: "visit_academy",
  exploreDocumentation: "explore_documentation",
};

export const FOUNDATIONAL_SETUP_STEPS_NAME = {
  institutionalConfiguration: "institutional_configuration",
  generalLedger: "general_ledger",
  transactionCodes: "transaction_codes",
  networks: "networks",
  customerRelationshipTypes: "customer_relationship_types",
  productConfiguration: "product_configuration",
};

export const SIDEBAR_STATUS: Record<
  "not_started" | "in_progress" | "completed",
  "NOT_STARTED" | "INPROGRESS" | "COMPLETED"
> = {
  not_started: "NOT_STARTED",
  in_progress: "INPROGRESS",
  completed: "COMPLETED",
};

export const WORKFLOW_STATUS = {
  completed: "completed",
};

export enum IC_ENTITY {
  "account_group" = "account_group",
  "asset_catalog" = "asset_catalog",
  "account_type" = "account_type",
  "financial_calendar" = "financial_calendar",
  "financial_organization" = "financial_organization",
  "bank_parameters" = "bank_parameters",
}

export enum SUMMARY_LANDING_SCREEN_STATUS {
  "unmet" = "unmet",
  "not_started" = "not_started",
  "in_progress" = "in_progress",
  "completed" = "completed",
  "prod_completed" = "prod_completed",
}

export enum IC_CONFIG_ORDER {
  "account_group" = 1,
  "asset_catalog",
  "account_type",
  "financial_calendar",
  "financial_organization",
  "bank_parameters",
}

export const IC_FIN_ORG_STATUS_PREFIXES = {
  gen_party: "gen_party",
  gen_org_party: "gen_org_party",
  fin_org_spec: "fin_org_spec",
  // party_us: "party_us", This will be added if we need Party US Bank Params
};

export type ICConfigStatusKey = keyof typeof IC_CONFIG_ORDER;

// Workflow api status keys
export enum ACCOUNT_GROUP_STEPS {
  "acc_grp_foundation_details" = "acc_grp_foundation_details",
  "acc_grp_number_identification" = "acc_grp_number_identification",
  "acc_grp_composite_field" = "acc_grp_composite_field",
  "acc_grp_advanced_config" = "acc_group_completed",
}

export enum ASSET_CATALOG_STEPS {
  "asset_cat_foundation_detail" = "asset_cat_foundation_detail",
  "asset_cat_origin_classification" = "asset_cat_origin_classification",
  "asset_cat_fin_spec" = "asset_cat_fin_spec",
  "asset_cat_gen_ledger" = "asset_catalog_completed",
}

export enum ACCOUNT_TYPE_STEPS {
  "acc_type_foundation_detail" = "acc_type_foundation_detail",
  "acc_type_reln_foundation_detail" = "acc_type_reln_foundation_detail",
  "acc_type_reln_party_spec" = "acc_type_reln_party_spec",
  "acc_type_reln_add_selections" = "acc_type_reln_add_selections",
  "acct_type_limits" = "acct_type_limits",
  "acct_type_summary" = "acc_type_completed",
}

export enum ACCOUNT_TYPE_STAGE_ORDER {
  "acc_type_foundation_detail" = 0,
  "acc_type_reln_foundation_detail",
  "acc_type_reln_party_spec",
  "acc_type_reln_add_selections",
  "acct_type_limits",
  "acct_type_summary",
}

export enum ACCOUNT_GROUP_STAGE_ORGER {
  "acc_grp_foundation_details" = 0,
  "acc_grp_number_identification",
  "acc_grp_composite_field",
  "acc_grp_advanced_config",
}

export enum FINANCIAL_CALENDAR_STEPS {
  "fin_cal_foundation_details" = "fin_cal_foundation_details",
  "fin_cal_business_day_config" = "fin_cal_business_day_config",
  "fin_cal_holiday_config" = "fin_calendar_completed",
}

export enum FINANCIAL_ORGANIZATION_STEPS {
  "fin_org_gen_party_params" = "fin_org_gen_party_params",
  "fin_org_gen_org_party_params" = "fin_org_gen_org_party_params",
  "fin_org_specific_params" = "fin_org_spec_completed",
}

export enum FIN_ORG_SUB_STEPS {
  // Gen Party Params
  "gen_party_identification" = "gen_party_identification",
  "gen_party_party_emails_detail" = "gen_party_party_emails_detail",
  "gen_party_phone_details" = "gen_party_phone_details",
  "gen_party_tax_specification" = "gen_party_tax_specification",
  "gen_party_specification" = "gen_party_specification",
  "gen_party_location_contact_info" = "gen_party_location_contact_info",
  "gen_party_international_transfer_intent" = "gen_party_completed",

  // Gen Org Party Params
  "gen_org_party_identification" = "gen_org_party_identification",
  "gen_org_party_tax_spec" = "gen_org_party_tax_spec",
  "gen_org_party_spec" = "gen_org_party_spec",
  "gen_org_party_location_contact_info" = "gen_org_party_completed",

  // Fin Org Spec Params
  "fin_org_identification" = "fin_org_spec_identification",
  "fin_org_spec" = "fin_org_spec",
  "fin_org_other_identification" = "fin_org_spec_other_identification",
  "fin_org_corr_network_identification" = "fin_org_spec_corr_network_identification",
  "fin_org_destination_network_origin" = "fin_org_spec_destination_network_origin",
  "fin_org_origin_network_origin" = "fin_org_spec_origin_network_origin",
  "fin_org_network_detail_summary" = "fin_org_spec_network_summary",
  "fin_org_rules" = "fin_org_spec_completed",

  // Party US Bank Params
  "party_us_spec_fin_org_spec" = "party_us_spec_fin_org_spec",
  "party_us_fedwire_details" = "completed_party_us",

  // Party Limit
  "party_limit_add_limit" = "party_limit_add_limit",
  "party_limit_accumulated_transaction" = "party_limit_accumulated_transaction",
  "party_limit_single_transaction" = "party_limit_transaction",
  "party_limit_gen_limit" = "party_limit_gen_limit",
}

export enum BANK_PARAMS_STEPS {
  "bank_params_foundational_details" = "bank_params_foundational_details",
  "bank_params_rates_currencies_and_identifiers" = "bank_params_rates_currencies_and_identifiers",
  "bank_params_cutoffs_limits_thresholds" = "bank_params_cutoffs_limits_thresholds",
  "bank_params_additional_specifications" = "bank_params_additional_specifications",
  "bank_params_base_savings_rates" = "bank_params_base_savings_rates",
  "bank_params_loan_penalty_rate" = "bank_params_loan_penalty_rate",
  "bank_params_us_bank_params_info" = "bank_params_us_bank_params_info",
  "bank_params_fdic_370_related_fields" = "bank_params_fdic_370_related_fields",
  "bank_params_sba_lending_party_email" = "bank_params_sba_lending_party_email",
  "bank_params_sba_lending_party_phone" = "bank_params_sba_lending_party_phone",
  "bank_params_scra_properties" = "bank_params_scra_properties",
  "bank_params_gl_extract_file_formats" = "bank_params_gl_extract_file_formats",
  "bank_params_tax_party_email_contact" = "bank_params_tax_party_email_contact",
  "bank_params_tax_party_phone_contact" = "bank_params_tax_party_phone_contact",
  "bank_params_priority_posting" = "bank_params_completed",
}

/** Note: Ordering matters, DO NOT CHANGE.*/
export const IC_CONFIG_STAGE_MAPPING = {
  [IC_ENTITY.account_group]: ROUTE_PATH.IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS,
  [IC_ENTITY.asset_catalog]: ROUTE_PATH.IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS,
  [IC_ENTITY.account_type]: ROUTE_PATH.IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS,
  [IC_ENTITY.financial_calendar]:
    ROUTE_PATH.IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS,
  [IC_ENTITY.financial_organization]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION,
  [IC_ENTITY.bank_parameters]: ROUTE_PATH.IC_BANK_PARAMS_FOUNDATION_DETAILS,
  [FINANCIAL_ORGANIZATION_STEPS.fin_org_gen_party_params]:
    ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION,
  [FINANCIAL_ORGANIZATION_STEPS.fin_org_gen_org_party_params]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION,

  // Account Group
  [ACCOUNT_GROUP_STEPS.acc_grp_foundation_details]:
    ROUTE_PATH.IC_ACCOUNT_GROUP_NUMBERING_IDENTIFICATION,
  [ACCOUNT_GROUP_STEPS.acc_grp_number_identification]:
    ROUTE_PATH.IC_ACCOUNT_GROUP_COMPOSITE_FIELD_CONFIGURATION,
  [ACCOUNT_GROUP_STEPS.acc_grp_composite_field]:
    ROUTE_PATH.IC_ACCOUNT_GROUP_ADVANCED_CONFIGURATION,
  [ACCOUNT_GROUP_STEPS.acc_grp_advanced_config]:
    ROUTE_PATH.IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS,

  // Asset Catalog
  [ASSET_CATALOG_STEPS.asset_cat_foundation_detail]:
    ROUTE_PATH.IC_ASSET_ORIGIN_AND_CLASSIFICATION,
  [ASSET_CATALOG_STEPS.asset_cat_origin_classification]:
    ROUTE_PATH.IC_ASSET_FINANCIAL_SPECIFICATION,
  [ASSET_CATALOG_STEPS.asset_cat_fin_spec]:
    ROUTE_PATH.IC_ASSET_GENERAL_LEDGER_DETAILS,
  [ASSET_CATALOG_STEPS.asset_cat_gen_ledger]:
    ROUTE_PATH.IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS,

  // Account Type
  [ACCOUNT_TYPE_STEPS.acc_type_foundation_detail]:
    ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS,
  [ACCOUNT_TYPE_STEPS.acc_type_reln_foundation_detail]:
    ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_PARTY_SPECIFICATIONS,
  [ACCOUNT_TYPE_STEPS.acc_type_reln_party_spec]:
    ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_ADDITIONAL_SELECTIONS,
  [ACCOUNT_TYPE_STEPS.acc_type_reln_add_selections]:
    ROUTE_PATH.IC_ACCOUNT_TYPES_ADD_LIMIT,
  [ACCOUNT_TYPE_STEPS.acct_type_limits]:
    ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY,
  [ACCOUNT_TYPE_STEPS.acct_type_summary]:
    ROUTE_PATH.IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS,

  // Financial Calendar

  [FINANCIAL_CALENDAR_STEPS.fin_cal_foundation_details]:
    ROUTE_PATH.IC_FINANCIAL_CALENDER_BUSINESS_DAY_CONFIG,
  [FINANCIAL_CALENDAR_STEPS.fin_cal_business_day_config]:
    ROUTE_PATH.IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION,
  [FINANCIAL_CALENDAR_STEPS.fin_cal_holiday_config]:
    ROUTE_PATH.IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS,

  // Financial Organization
  //DUMMY_PATH added to figure out correct workflow step.
  [IC_FIN_ORG_STATUS_PREFIXES.gen_party]: "DUMMY_PATH",
  // General Party Params
  [FIN_ORG_SUB_STEPS.gen_party_identification]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS,
  [FIN_ORG_SUB_STEPS.gen_party_party_emails_detail]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS,
  [FIN_ORG_SUB_STEPS.gen_party_phone_details]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION,
  [FIN_ORG_SUB_STEPS.gen_party_tax_specification]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION,
  [FIN_ORG_SUB_STEPS.gen_party_specification]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION,
  [FIN_ORG_SUB_STEPS.gen_party_location_contact_info]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT,
  [FIN_ORG_SUB_STEPS.gen_party_international_transfer_intent]:
    ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION,
  // Gen org party params
  [IC_FIN_ORG_STATUS_PREFIXES.gen_org_party]: "DUMMY_PATH",
  [FIN_ORG_SUB_STEPS.gen_org_party_identification]:
    ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_TAX_SPECIFICATION,
  [FIN_ORG_SUB_STEPS.gen_org_party_tax_spec]:
    ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_SPECIFICATION,
  [FIN_ORG_SUB_STEPS.gen_org_party_spec]:
    ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY_LOCATION_AND_CONTACT,
  [FIN_ORG_SUB_STEPS.gen_org_party_location_contact_info]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION,
  // Fin org spec params
  [IC_FIN_ORG_STATUS_PREFIXES.fin_org_spec]: "DUMMY_PATH",
  [FIN_ORG_SUB_STEPS.fin_org_identification]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_OTHER_IDENTIFICATION,
  [FIN_ORG_SUB_STEPS.fin_org_other_identification]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_FIN_ORG_SPECIFICATIONS,
  [FIN_ORG_SUB_STEPS.fin_org_spec]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION,
  [FIN_ORG_SUB_STEPS.fin_org_corr_network_identification]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK,
  [FIN_ORG_SUB_STEPS.fin_org_destination_network_origin]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK,
  [FIN_ORG_SUB_STEPS.fin_org_origin_network_origin]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY,
  [FIN_ORG_SUB_STEPS.fin_org_network_detail_summary]:
    ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM_RULES,
  [FIN_ORG_SUB_STEPS.fin_org_rules]:
    ROUTE_PATH.GENERAL_PARTY_PARAMETER_IDENTIFICATION,

  // Bank Parameters
  [BANK_PARAMS_STEPS.bank_params_foundational_details]:
    ROUTE_PATH.IC_BANK_PARAMS_RATES_CURRENCIES_IDENTIFIERS,
  [BANK_PARAMS_STEPS.bank_params_rates_currencies_and_identifiers]:
    ROUTE_PATH.IC_BANK_PARAMS_CUTOFF_LIMITS_THRESHOLD,
  [BANK_PARAMS_STEPS.bank_params_cutoffs_limits_thresholds]:
    ROUTE_PATH.IC_BANK_PARAMS_ADDITIONAL_SPECIFICATION,
  [BANK_PARAMS_STEPS.bank_params_additional_specifications]:
    ROUTE_PATH.IC_BANK_PARAMS_BASE_SAVING_RATE,
  [BANK_PARAMS_STEPS.bank_params_base_savings_rates]:
    ROUTE_PATH.IC_BANK_PARAMS_LOAN_PENALTY_RATE,
  [BANK_PARAMS_STEPS.bank_params_loan_penalty_rate]:
    ROUTE_PATH.IC_BANK_PARAMS_US_FINANCIAL_INST_PARAMS_INFO,
  [BANK_PARAMS_STEPS.bank_params_us_bank_params_info]:
    ROUTE_PATH.IC_BANK_PARAMS_FDIC_370_RELATED_FIELD,
  [BANK_PARAMS_STEPS.bank_params_fdic_370_related_fields]:
    ROUTE_PATH.IC_BANK_PARAMS_SBA_LENDING_PARTY_EMAIL,
  [BANK_PARAMS_STEPS.bank_params_sba_lending_party_email]:
    ROUTE_PATH.IC_BANK_PARAMS_SBA_LENDING_PARTY_PHONE,
  [BANK_PARAMS_STEPS.bank_params_sba_lending_party_phone]:
    ROUTE_PATH.IC_BANK_PARAMS_SCRA_PROPERTIES,
  [BANK_PARAMS_STEPS.bank_params_scra_properties]:
    ROUTE_PATH.IC_BANK_PARAMS_GL_EXTRACT_FILE_FORMATE,
  [BANK_PARAMS_STEPS.bank_params_gl_extract_file_formats]:
    ROUTE_PATH.IC_BANK_PARAMS_TAX_PARTY_EMAIL_CONTACT,
  [BANK_PARAMS_STEPS.bank_params_tax_party_email_contact]:
    ROUTE_PATH.IC_BANK_PARAMS_TAX_LENDING_PARTY_PHONE,
  [BANK_PARAMS_STEPS.bank_params_tax_party_phone_contact]:
    ROUTE_PATH.IC_BANK_PARAMS_PRIORITY_POSTING,
  [BANK_PARAMS_STEPS.bank_params_priority_posting]:
    ROUTE_PATH.IC_BANK_PARAMS_FOUNDATION_DETAILS,
};

/* General Ledger */

export enum GL_CONFIG_ORDER {
  "accounting_segments" = 1,
  "accounts",
  "system_accounts",
  "sets",
}

// Read speed for english language
export const DOC_READ_SPEED_WORDS_PER_MIN = 150;
export const TIME_TO_VIEW_IMAGE = 0.25; // 15s

export class DocumentTypes {
  static readonly TrainingModule = "training_module";
  static readonly TrainingModuleGroup = "training_module_group";
  static readonly TrainingDocument = "training_document";
  static readonly VideoModule = "video_module";
  static readonly DocNav = "docnav";
  static readonly Document = "document";
  static readonly DocumentCenter = "document_center";
  static readonly DocumentCenterConfig = "doc_center_config";
  static readonly Pattern = "pattern";
  static readonly VideoSeriesCatalog = "video_series_catalog";
  static readonly CodeWalkthrough = "code_walkthrough";
  static readonly ApiLibrary = "api_library";
  static readonly ApiNav = "api_nav";
}

export const RestrictedTags = ["Restricted"]; //tags are case-sensitive

export class DocumentSliceTypes {
  static readonly Paragraph = "Paragraph";
  static readonly SmallCaseParagraph = "paragraph";
  static readonly ParagraphLegacy = "Paragraphlegacy";
  static readonly CodeBlock = "CodeBlock";
  static readonly CodeSnippet = "CodeSnippet";
  static readonly CodeWalkthroughPrismic = "CodeWalkthroughPrismic";
  static readonly CodeWalkthrough = "CodeWalkthrough";
  static readonly ImageWidth = "ImageWidth";
  static readonly Markdown = "Markdown";
  static readonly ImageTextBlock = "ImageTextBlock";
  static readonly Excerpt = "Excerpt";
  static readonly VimeoEmbed = "VimeoEmbed";
  static readonly VimeoEmbed2 = "VimeoEmbed2";
  static readonly HighlightedText = "HighlightedText";
  static readonly HighlightedTextLegacy = "HighlightedTextLegacy";
  static readonly Example = "Example";
  static readonly PostmanCollection = "PostmanCollection";
  static readonly FileDownload = "FileDownload";
  static readonly CodeSideBySide = "CodeSideBySide";
  static readonly Course = "Course";
  static readonly DocCenterTopic = "doc_center_topic";
  static readonly AdditionalResources = "resources_links_section";
}

export class colorSelect {
  static readonly dict = {
    "Caution (Yellow)": "alert-yellow",
    "Warning (Pink)": "alert-pink",
    "Note (Blue)": "alert-blue",
  };
}

export class alertIconColorSelect {
  static readonly dict = {
    "Caution (Yellow)": "orange",
    "Warning (Pink)": "red",
    "Note (Blue)": "blue",
  };
}

export class alertIconsStatus {
  static readonly dict = {
    "Note (Blue)": "info",
    "Warning (Pink)": "error",
    "Caution (Yellow)": "warning",
  };
}

export class Headings {
  static readonly dict = {
    heading1: "h1",
    heading2: "h2",
    heading3: "h3",
  };
  static readonly indexableSlices = [
    DocumentSliceTypes.Paragraph,
    DocumentSliceTypes.CodeWalkthrough,
    DocumentSliceTypes.Excerpt,
    DocumentSliceTypes.CodeSnippet,
    DocumentSliceTypes.HighlightedTextLegacy,
  ];
}

export enum GL_ENTITY {
  "accounting_segments" = "accounting_segments",
  "accounts" = "accounts",
  "system_accounts" = "system_accounts",
  "sets" = "sets",
  "positions" = "positions",
}

export enum GL_STEPS {
  "accounting_segments" = "completed_accounting_segments",
  "accounts" = "completed_accounts",
  "system_accounts" = "completed_system_accounts",
  "sets" = "completed_sets",
}

/** Note: Ordering matters, DO NOT CHANGE.*/
export const GL_CONFIG_STAGE_MAPPING = {
  [GL_ENTITY.accounting_segments]: ROUTE_PATH.GL_ACCOUNTING_SEGMENTS,
  [GL_ENTITY.accounts]: ROUTE_PATH.GL_ACCOUNTS,
  [GL_ENTITY.system_accounts]: ROUTE_PATH.GL_SYSTEM_ACCOUNTS,
  [GL_ENTITY.sets]: ROUTE_PATH.GL_SETS,
  [GL_STEPS.accounting_segments]: ROUTE_PATH.GL_ACCOUNTING_SEGMENTS,
  [GL_STEPS.accounts]: ROUTE_PATH.GL_ACCOUNTS,
  [GL_STEPS.system_accounts]: ROUTE_PATH.GL_SYSTEM_ACCOUNTS,
  [GL_STEPS.sets]: ROUTE_PATH.GL_SETS,
};
export type ICConfigStatusType =
  | ValueOf<typeof ACCOUNT_GROUP_STEPS>
  | ValueOf<typeof ASSET_CATALOG_STEPS>
  | ValueOf<typeof ACCOUNT_TYPE_STEPS>
  | ValueOf<typeof FINANCIAL_CALENDAR_STEPS>
  | ValueOf<typeof FINANCIAL_ORGANIZATION_STEPS>
  | ValueOf<typeof BANK_PARAMS_STEPS>;

export type GLConfigStatusType = ValueOf<typeof GL_STEPS>;
export type GLStatusKey = keyof typeof GL_CONFIG_ORDER;
export type CRTStatusKey = keyof typeof CRT_CONFIG_ORDER;
export type TCStatusKey = keyof typeof TC_STAGE_ORDER;

export enum SUMMARY_LANDING_PAGE_TYPES {
  ic = "Institutional Configuration",
  gl = "General Ledger Setup",
  crt = "Customer relationship types",
  tc = "Transaction Code Setup",
}
export const GENERAL_LEDGER = {
  glUpperCaseName: "GENERAL LEDGER SETUP",
  glName: "General ledger",
};
export const DATE_FORMAT = {
  shortDate: "MM/DD/YY",
  longDate: "MM/DD/YYYY",
  longDateWithCommma: "YYYY,MM,DD",
  international: "YYYY-MM-DD",
};

export const TIME_FORMAT = {
  shortTime: "HH:MM:SS",
  placeHolderTime: "12:00:00 PM",
  specificTime: "HH:mm:ss",
};

export const DATE_TIME_FORMAT = {
  shortDateTime: "MM/DD/YY HH:mm:ss",
};

export const DEFAULT_CONSTS = {
  percentage0: "0%",
};

export enum TC_STAGE_STATUS {
  "foundational_details" = "foundational_details",
  "tag_setup" = "tag_setup",
  "entry_setup" = "entry_setup",
  "access_rights" = "access_rights",
}

export enum TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS {
  "entry_foundational" = "entry_foundational",
  "entry_advanced" = "entry_advanced",
  "entry_statistical_group" = "entry_statistical_group",
  "entry_account_segments" = "entry_account_segments",
  "entry_template" = "entry_template",
  "entry_hold_specification" = "entry_hold_specification",
  "entry_field_specification" = "entry_field_specification",
  "entry_summary" = "entry_summary",
}

// Using this map to validate incorrect workflow step
/** Note: Ordering matters, DO NOT CHANGE.*/
export const TRANS_CODE_CONFIG_STAGE_MAPPING = {
  [TC_STAGE_STATUS.foundational_details]: "",
  [TC_STAGE_STATUS.tag_setup]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_foundational]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_advanced]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_statistical_group]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_account_segments]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_template]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_hold_specification]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_field_specification]: "",
  [TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_summary]: "",
};

export enum TC_STAGE_ORDER {
  "foundational_details" = 0,
  "tag_setup",
  "entry_setup",
}

export enum TC_CONFIG_ORDER {
  "foundational_details" = 1,
  "tag_setup",
  "entry_setup",
}

export enum TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER {
  "entry_foundational" = 0,
  "entry_advanced",
  "entry_statistical_group",
  "entry_account_segments",
  "entry_template",
  "entry_hold_specification",
  "entry_field_specification",
  "entry_summary",
}

export const TC_STAGE_LIST = [
  TC_STAGE_STATUS.foundational_details,
  TC_STAGE_STATUS.tag_setup,
  TC_STAGE_STATUS.entry_setup,
];

export const PRIMARY_RADIO_VALUE: string = "PRIMARY";
export const SECONDARY_RADIO_VALUE: string = "SECONDARY";

export enum TC_SUMMARY_LANDING_SCREEN_STATUS {
  "unmet" = "unmet",
  "completed" = "completed",
}

export const TC_TABLE_COLUMN_DEF = [
  { header: "Code", accessorKey: "trnCode", enableSorting: true },
  { header: "Description", accessorKey: "desc", enableSorting: true },
  { header: "", accessorKey: "action", enableSorting: false },
];

export const TC_ENTRY_SETUP_SUMMARY_LIST = [
  {
    name: "Transaction entry foundational details",
    href: `${ROUTE_PATH.TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS}`,
  },
  {
    name: "Transaction entry advanced setup",
    href: `${ROUTE_PATH.TC_TRANSACTION_ENTRY_ADVANCED_SETUP}`,
  },
  {
    name: "Statistical group expression entries",
    href: `${ROUTE_PATH.TC_TRANSACTION_ENTRY_STATISTIC_GROUP}`,
  },
  {
    name: "Accounting segments",
    href: `${ROUTE_PATH.TC_TRANSACTION_ENTRY_ACCOUNTING_SEGMENTS}`,
  },
  {
    name: "Templates",
    href: `${ROUTE_PATH.TC_TRANSACTION_ENTRY_TEMPLATES}`,
  },
  {
    name: "Hold specifications",
    href: `${ROUTE_PATH.TC_TRANSACTION_ENTRY_HOLD_SPECIFICATIONS}`,
  },
  {
    name: "Field specifications (immutable vs required)",
    href: `${ROUTE_PATH.TC_TRANSACTION_ENTRY_FIELD_SPECIFICATIONS}`,
  },
];

export enum TC_ACTIONS {
  DUPLICATE = "duplicate",
}

export enum CRT_SUMMERY_LANDING_SCREEN_STATUS {
  "unmet" = "unmet",
  "not_started" = "not_started",
  "in_progress" = "in_progress",
  "completed" = "completed",
}
export enum CRT_ENTITY {
  "general_setup" = "general_setup",
  "party_relationships" = "party_relationships",
}
export enum CRT_STEPS {
  "general_setup" = "completed_general_setup",
  "party_relationships" = "completed_party_relationships",
}

export enum CRT_CONFIG_ORDER {
  "general_setup" = 1,
  "party_relationships",
}

// Workflow api status keys
export enum GENERAL_SETUP {
  "general_setup_config" = "general_setup_completed",
}

export enum PARTY_RELATIONSHIPS_STEPS {
  "party_relationships_crt" = "party_relationships_completed",
}
export const CRT_TABLE_COLUMN_DEF = [
  { header: "Name", accessorKey: "custRelType", enableSorting: true },
  { header: "Description", accessorKey: "desc", enableSorting: true },
  { header: "", accessorKey: "action", enableSorting: false },
];

export type CRTConfigStatusType =
  | ValueOf<typeof GENERAL_SETUP>
  | ValueOf<typeof PARTY_RELATIONSHIPS_STEPS>;

export const CRT_CONFIG_STAGE_MAPPING = {
  [CRT_ENTITY.general_setup]: ROUTE_PATH.CRT_GENERAL_SETUP,
  [CRT_ENTITY.party_relationships]: ROUTE_PATH.CRT_PARTY_RELATIONSHIPS,
  [CRT_STEPS.general_setup]: ROUTE_PATH.CRT_GENERAL_SETUP,
  [CRT_STEPS.party_relationships]: ROUTE_PATH.CRT_PARTY_RELATIONSHIPS,
};

//IC and GL Completion Page Data
export const COMPLETION_SCREEN_DATA = {
  "institutional-configuration": [
    {
      entityType: IC_ENTITY.account_group,
      heading: "Nice work! You've completed setting up your account group",
      createBtnLabel: "account group",
      back: ROUTE_PATH["IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS"],
      label: [
        "Account group foundational details",
        "Numbering & identification",
        "Composite & field configuration",
        "Advanced configurations",
      ],
    },
    {
      entityType: IC_ENTITY.asset_catalog,
      heading: "Nice work! You've completed setting up your asset catalog",
      createBtnLabel: "asset catalog",
      back: ROUTE_PATH["IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS"],
      label: [
        "Asset catalog foundational details",
        "Asset origin & dlassification",
        "Financial specifications",
        "General ledger details",
      ],
    },
    {
      entityType: IC_ENTITY.account_type,
      heading: "Nice work! You've completed setting up your account types",
      createBtnLabel: "account types",
      back: ROUTE_PATH["IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS"],
      label: [
        "Account type foundational details",
        "Account type relationship foundational details",
        "Account type relationship party specifications",
        "Account type relationship additional selections",
        "Add limit component",
        "Account type summary",
      ],
    },
    {
      entityType: IC_ENTITY.financial_calendar,
      heading: "Nice work! You've completed setting up your financial calendar",
      createBtnLabel: "financial calendar",
      back: ROUTE_PATH["IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS"],
      label: [
        "Financial calendar foundational details",
        "Business day configuration",
        "Holiday configuration",
      ],
    },
    {
      entityType: IC_ENTITY.financial_organization,
      heading:
        "Nice work! You've completed setting up your financial organization",
      createBtnLabel: "financial organization",
      back: ROUTE_PATH["GENERAL_PARTY_PARAMETER_IDENTIFICATION"],
      label: [
        "General party parameters",
        "General organization party parameters",
        "Financial organization-specific parameters",
      ],
    },
    {
      entityType: IC_ENTITY.bank_parameters,
      heading: "Nice work! You've completed setting up your institution",
      createBtnLabel: "financial institution",
      back: ROUTE_PATH["IC_BANK_PARAMS_FOUNDATION_DETAILS"],
      label: [
        "Foundational details",
        "Rates, currencies, and identifiers",
        "Cutoffs, limits & thresholds",
        "Additional specifications",
        "Base savings rates",
        "Loan penalty rate",
        "US financial institution (bank) parameters & information",
        "[US] FDIC370 related fields",
        "[US] SBA lending party email",
        "[US] SBA lending party phone",
        "[US] SCRA properties",
        "GL extract file formats",
        "Tax party email contact",
        "Tax party phone contact",
        "Priority posting",
      ],
    },
  ],
  "general-ledger": [
    {
      entityType: GL_ENTITY.accounting_segments,
      heading:
        "Nice work! You've completed setting up your accounting segments",
      createBtnLabel: "accounting segments",
      back: ROUTE_PATH["GL_ACCOUNTING_SEGMENTS"],
      label: ["Accounting segments"],
    },
    {
      entityType: GL_ENTITY.accounts,
      heading: "Nice work! You've completed setting up your accounts",
      createBtnLabel: "accounts",
      back: ROUTE_PATH["GL_ACCOUNTS"],
      label: ["Accounts"],
    },
    {
      entityType: GL_ENTITY.system_accounts,
      heading: "Nice work! You've completed setting up your system accounts",
      createBtnLabel: "system accounts",
      back: ROUTE_PATH["GL_SYSTEM_ACCOUNTS"],
      label: ["System accounts"],
    },
    {
      entityType: GL_ENTITY.sets,
      heading: "Nice work! You've completed setting up your sets",
      createBtnLabel: "sets",
      back: ROUTE_PATH["GL_SETS"],
      label: ["Sets"],
    },
  ],
};

/**
 * Where Organization ID is in local storage
 */
export const ORGANIZATION_COOKIE = "fxcOrg";
/**
 * Is it proj or env?
 */
export const ENV_TYPE_KEY = "environment-type";

export const keyGroupingEnabledOrgDropdown = "org-grouping-enabled";

export const ORG_PHASE = {
  production: "Production",
  prospect: "Prospect",
  implementation: "Implementation",
  uat: "User Acceptance Testing",
};

// List of org phases which should treat foundational and product config setup to be completed
export const COMPLETED_STATE_ORG_PHASES = [
  ORG_PHASE.production,
  ORG_PHASE.prospect,
  ORG_PHASE.uat,
];

// List of org phases which should treat all config items as completed if no workflow exists for those items
export const NO_WORKFLOW_ORG_PHASES = [ORG_PHASE.production, ORG_PHASE.uat];

// List of org phases which for which Dashboard should be in completed state
export const DASHBOARD_COMPLETED_ORG_PHASES = [
  ORG_PHASE.production,
  ORG_PHASE.uat,
];

export const QualtricsEnvId = "SV_3ItaqQSudEzlLjU";

export const TICKET_REQUESTS = {
  sandbox: "fast-sandbox",
  environment: "fast-environment",
} as const;

export const TICKET_STATUS = {
  done: "Done",
  backLog: "Backlog",
};

export const HELPFUL_RESOURCES_SLUG = {
  ic: "ic-summary",
  gl: "gl-summary",
  crt: "crt-summary",
  tc: "tc-summary",
};

export const CONSOLE_URLS = [
  "product-configuration",
  "institutional-configuration",
  "general-ledger",
  "transaction-codes",
  "customer-relationship-types",
];

export const ACCT_TYPE_REL_SUMMARY_LIST = [
  {
    name: "Account type relationship foundational details",
    href: `${ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS}`,
  },
  {
    name: "Account type relationship party specifications",
    href: `${ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_PARTY_SPECIFICATIONS}`,
  },
  {
    name: "Account type relationship additional selections",
    href: `${ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_ADDITIONAL_SELECTIONS}`,
  },
  {
    name: "Add Limit Component",
    href: `${ROUTE_PATH.IC_ACCOUNT_TYPES_ADD_LIMIT}`,
  },
];

export const IC_COMPONENT_NAMES = [
  IC_ENTITY.account_group,
  IC_ENTITY.asset_catalog,
  IC_ENTITY.account_type,
  IC_ENTITY.financial_calendar,
  IC_ENTITY.financial_organization,
  IC_ENTITY.bank_parameters,
];

export const GL_COMPONENT_NAMES = [
  GL_ENTITY.accounting_segments,
  GL_ENTITY.accounts,
  GL_ENTITY.system_accounts,
  GL_ENTITY.sets,
  GL_ENTITY.positions,
];

const PERMISSIONS = {
  // IC
  acctGroup: "AcctGroup",
  assetCatalogue: "AssetCatalogue",
  acctType: "AcctType",
  calendar: "Calendar",
  party_org_fininst: "Party_org_fininst",
  bankparam: "Bankparam",
  feeCalcDtl: "FeeCalcDtl",
  accumTrnLimit: "AccumTrnLimit",
  trnLimit: "TrnLimit",
  matrixType: "MatrixType",
  role: "Role",
  exceptRule: "ExceptRule",

  // GL
  acctgSeg: "AcctgSeg",
  acct_gl: "Acct_gl",
  configSystemAcct: "ConfigSystemAcct",
  glSet: "GlSet",
  posn_gl: "Posn_gl",
  glSubBalType: "GlSubBalType",

  // TranCode
  trnCode: "TrnCode",
  trnCodeEntries: "TrnCodeEntries",
  trnCodeEntriesTemplates: "TrnCodeEntriesTemplates",
  vaildTags: "ValidTags",

  // CustRelType
  custRel: "CustRelType",

  // Common
  trnStatGroup: "TrnStatGroup",
  language: "Language",
  network: "Network",
  tmZone: "TmZone",
  cntry: "Cntry",
  cntrySubdivision: "CntrySubdivision",

  // Product
  prod_bk: "Prod_bk",
  prod_bkLnDelq: "Prod_bkLnDelq",
  componentCollateral: "ComponentCollateral",
  componentDepChrgOff: "ComponentDepChrgOff",
  componentFee: "ComponentFee",
  componentInt: "ComponentInt",
  componentLimit: "ComponentLimit",
  componentNsf: "ComponentNsf",
  componentRepay: "ComponentRepay",
  componentReward: "ComponentReward",
  componentRules: "ComponentRules",
  componentTd: "ComponentTd",
  componentTrnOpt: "ComponentTrnOpt",

  prodType: "ProdType",
  prodSubType: "ProdSubType",
  prodGroup: "ProdGroup",
  prodProgramGroup: "ProdProgramGroup",
  matrixDims: "MatrixDims",
};

const COMMON_PRODUCT_PERMISSIONS = [
  `${PERMISSIONS.componentInt}Create`,
  `${PERMISSIONS.componentFee}Create`,
  `${PERMISSIONS.componentRules}Create`,
  `${PERMISSIONS.prod_bk}Create`,
  `${PERMISSIONS.prod_bk}Patch`,
  `${PERMISSIONS.prod_bk}Update`,
  `Create${PERMISSIONS.matrixDims}`,
  `Update${PERMISSIONS.matrixDims}`,
];

// Create/Update for Prod Config is driven by the same set of permissions
const PC_DEPOSIT_CREATE_UPDATE_PERMISSIONS = [
  ...COMMON_PRODUCT_PERMISSIONS,
  `${PERMISSIONS.componentReward}Create`,
  `${PERMISSIONS.componentLimit}Create`,
  `${PERMISSIONS.componentNsf}Create`,
  `${PERMISSIONS.componentTd}Create`,
  `${PERMISSIONS.componentDepChrgOff}Create`,
  `${PERMISSIONS.componentTrnOpt}Create`,

  // Add new popup
  `${PERMISSIONS.glSet}Create`,
  `${PERMISSIONS.feeCalcDtl}Create`,
  `${PERMISSIONS.exceptRule}Create`,
  `${PERMISSIONS.matrixType}Create`,
  `${PERMISSIONS.prodProgramGroup}Create`,
];
const PC_LOAN_CREATE_UPDATE_PERMISSIONS = [
  ...COMMON_PRODUCT_PERMISSIONS,
  `${PERMISSIONS.componentRepay}Create`,
  `${PERMISSIONS.componentCollateral}Create`,
  `${PERMISSIONS.prod_bkLnDelq}Create`,
  `${PERMISSIONS.prod_bkLnDelq}Update`,
  `${PERMISSIONS.prod_bkLnDelq}Delete`,

  // Add new popup
  `${PERMISSIONS.glSet}Create`,
  `${PERMISSIONS.feeCalcDtl}Create`,
];

export const ENTITLEMENT = {
  INSTITUTIONAL_CONFIGURATION: {
    READ: [
      `${PERMISSIONS.acctGroup}List`,
      `${PERMISSIONS.assetCatalogue}List`,
      `${PERMISSIONS.acctType}List`,
      `${PERMISSIONS.calendar}List`,
      `${PERMISSIONS.party_org_fininst}List`,
      `${PERMISSIONS.bankparam}List`,
      `${PERMISSIONS.acct_gl}List`, // required in assetCatalogue
      `${PERMISSIONS.tmZone}List`, // Calendar
      `${PERMISSIONS.cntry}List`, // Party_org_fininst
      `${PERMISSIONS.cntrySubdivision}List`, // Party_org_fininst
      `${PERMISSIONS.network}List`, // Party_org_fininst
      `${PERMISSIONS.exceptRule}List`, // Bankparams
      `${PERMISSIONS.trnCode}List`, // Bankparams

      // Add new popup
      `${PERMISSIONS.feeCalcDtl}List`,
      `${PERMISSIONS.accumTrnLimit}List`,
      `${PERMISSIONS.trnLimit}List`,
      `${PERMISSIONS.matrixType}List`,
      `${PERMISSIONS.role}List`,
    ],
    CREATE: [
      // POST
      `${PERMISSIONS.acctGroup}Create`,
      `${PERMISSIONS.assetCatalogue}Create`,
      `${PERMISSIONS.acctType}Create`,
      `${PERMISSIONS.calendar}Create`,
      `${PERMISSIONS.party_org_fininst}Create`,
      `${PERMISSIONS.bankparam}Create`,

      // Add new popup
      `${PERMISSIONS.feeCalcDtl}Create`,
      `${PERMISSIONS.accumTrnLimit}Create`,
      `${PERMISSIONS.trnLimit}Create`,
      `${PERMISSIONS.matrixType}Create`,
      `${PERMISSIONS.role}Create`,
    ],
    UPDATE: [
      // PATCH
      `${PERMISSIONS.acctGroup}Patch`,
      `${PERMISSIONS.assetCatalogue}Patch`,
      `${PERMISSIONS.acctType}Patch`,
      `${PERMISSIONS.calendar}Patch`,
      `${PERMISSIONS.party_org_fininst}Patch`,
      `${PERMISSIONS.bankparam}Patch`,

      // Add new popup
      `${PERMISSIONS.feeCalcDtl}Create`,
      `${PERMISSIONS.accumTrnLimit}Create`,
      `${PERMISSIONS.trnLimit}Create`,
      `${PERMISSIONS.matrixType}Create`,
      `${PERMISSIONS.role}Create`,
    ],
    OPTIONS: [
      `${PERMISSIONS.acctGroup}Options`,
      `${PERMISSIONS.assetCatalogue}Options`,
      `${PERMISSIONS.acctType}Options`,
      `${PERMISSIONS.calendar}Options`,
      `${PERMISSIONS.party_org_fininst}Options`,
      `${PERMISSIONS.bankparam}Options`,
      `${PERMISSIONS.componentLimit}Options`, // Acct Type

      // Add new popup
      `${PERMISSIONS.feeCalcDtl}Options`,
      `${PERMISSIONS.accumTrnLimit}Options`,
      `${PERMISSIONS.trnLimit}Options`,
      `${PERMISSIONS.matrixType}Options`,
      `${PERMISSIONS.role}Options`,
    ],
  },
  GENERAL_LEDGER: {
    READ: [
      `${PERMISSIONS.acctgSeg}List`,
      `${PERMISSIONS.acct_gl}List`,
      `${PERMISSIONS.configSystemAcct}List`,
      `${PERMISSIONS.glSet}List`,
      `${PERMISSIONS.posn_gl}List`,

      `${PERMISSIONS.acctGroup}List`,
      `${PERMISSIONS.glSubBalType}List`,
    ],
    CREATE: [
      `${PERMISSIONS.acctgSeg}Create`,
      `${PERMISSIONS.acct_gl}Create`,
      `${PERMISSIONS.configSystemAcct}Create`,
      `${PERMISSIONS.glSet}Create`,
    ],
    UPDATE: [
      `${PERMISSIONS.acctgSeg}Patch`,
      `${PERMISSIONS.acct_gl}Patch`,
      `${PERMISSIONS.configSystemAcct}Patch`,
      `${PERMISSIONS.glSet}Patch`,
    ],
    OPTIONS: [
      `${PERMISSIONS.acctgSeg}Options`,
      `${PERMISSIONS.acct_gl}Options`,
      `${PERMISSIONS.configSystemAcct}Options`,
      `${PERMISSIONS.glSet}Options`,
      `${PERMISSIONS.posn_gl}Options`,
    ],
  },
  TRANSACTION_CODE: {
    READ: [
      `${PERMISSIONS.trnCode}List`,
      `${PERMISSIONS.feeCalcDtl}List`,
      `${PERMISSIONS.trnStatGroup}List`,
      `${PERMISSIONS.language}List`,
      `${PERMISSIONS.acctGroup}List`,
      `${PERMISSIONS.trnLimit}List`,
      `${PERMISSIONS.network}List`,
      `${PERMISSIONS.acctgSeg}List`,
      `${PERMISSIONS.assetCatalogue}List`,
      `${PERMISSIONS.glSet}List`,
      PERMISSIONS.vaildTags,
    ],
    CREATE: [
      `${PERMISSIONS.trnCode}Create`,
      `${PERMISSIONS.trnCodeEntriesTemplates}Create`,
      `${PERMISSIONS.trnCodeEntriesTemplates}Delete`,
      `${PERMISSIONS.feeCalcDtl}Create`,
    ],
    UPDATE: [
      `${PERMISSIONS.trnCode}Update`,
      `${PERMISSIONS.trnCodeEntriesTemplates}Update`,
      `${PERMISSIONS.trnCodeEntriesTemplates}Delete`,
      `${PERMISSIONS.feeCalcDtl}Create`,
    ],
    OPTIONS: [
      `${PERMISSIONS.trnCode}Options`,
      `${PERMISSIONS.feeCalcDtl}Options`,
    ],
  },
  CUSTOMER_RELATIONSHIP: {
    READ: [`${PERMISSIONS.custRel}List`],
    CREATE: [`${PERMISSIONS.custRel}Create`],
    UPDATE: [`${PERMISSIONS.custRel}Patch`],
    OPTIONS: [`${PERMISSIONS.custRel}Options`],
  },
  PRODUCT_CONFIG_DEPOSIT: {
    READ: [
      `${PERMISSIONS.componentInt}List`,
      `${PERMISSIONS.componentFee}List`,
      `${PERMISSIONS.componentReward}List`,
      `${PERMISSIONS.componentLimit}List`,
      `${PERMISSIONS.componentNsf}List`,
      `${PERMISSIONS.componentTd}List`,
      `${PERMISSIONS.componentDepChrgOff}List`,
      `${PERMISSIONS.componentTrnOpt}List`,
      `${PERMISSIONS.componentRules}List`,
      `${PERMISSIONS.prod_bk}List`,
      `${PERMISSIONS.prodType}List`,
      `${PERMISSIONS.prodSubType}List`,
      `${PERMISSIONS.prodGroup}List`,
      `Get${PERMISSIONS.matrixDims}`,

      // Add new popup
      `${PERMISSIONS.glSet}List`,
      `${PERMISSIONS.feeCalcDtl}List`,
      `${PERMISSIONS.exceptRule}List`,
      `${PERMISSIONS.matrixType}List`,
      `${PERMISSIONS.prodProgramGroup}List`,
    ],
    CREATE: PC_DEPOSIT_CREATE_UPDATE_PERMISSIONS,
    UPDATE: PC_DEPOSIT_CREATE_UPDATE_PERMISSIONS,
    OPTIONS: [
      `${PERMISSIONS.componentInt}Options`,
      `${PERMISSIONS.componentFee}Options`,
      `${PERMISSIONS.componentReward}Options`,
      `${PERMISSIONS.componentLimit}Options`,
      `${PERMISSIONS.componentNsf}Options`,
      `${PERMISSIONS.componentTd}Options`,
      `${PERMISSIONS.componentDepChrgOff}Options`,
      `${PERMISSIONS.componentTrnOpt}Options`,
      `${PERMISSIONS.componentRules}Options`,
      `${PERMISSIONS.prod_bk}Options`,

      // Add new popup
      `${PERMISSIONS.glSet}Options`,
      `${PERMISSIONS.feeCalcDtl}Options`,
      `${PERMISSIONS.exceptRule}Options`,
      `${PERMISSIONS.matrixType}Options`,
      `${PERMISSIONS.prodProgramGroup}Options`,
    ],
  },
  PRODUCT_CONFIG_LOAN: {
    READ: [
      `${PERMISSIONS.componentInt}List`,
      `${PERMISSIONS.componentFee}List`,
      `${PERMISSIONS.componentRepay}List`,
      `${PERMISSIONS.componentCollateral}List`,
      `${PERMISSIONS.componentRules}List`,
      `${PERMISSIONS.prod_bk}List`,
      `${PERMISSIONS.prod_bkLnDelq}List`,
      `${PERMISSIONS.prodType}List`,
      `${PERMISSIONS.prodSubType}List`,
      `${PERMISSIONS.prodGroup}List`,
      `Get${PERMISSIONS.matrixDims}`,

      // Add new popup
      `${PERMISSIONS.glSet}List`,
      `${PERMISSIONS.feeCalcDtl}List`,
    ],
    CREATE: PC_LOAN_CREATE_UPDATE_PERMISSIONS,
    UPDATE: PC_LOAN_CREATE_UPDATE_PERMISSIONS,
    OPTIONS: [
      `${PERMISSIONS.componentInt}Options`,
      `${PERMISSIONS.componentFee}Options`,
      `${PERMISSIONS.componentRepay}Options`,
      `${PERMISSIONS.componentCollateral}Options`,
      `${PERMISSIONS.componentRules}Options`,
      `${PERMISSIONS.prod_bk}Options`,
      `${PERMISSIONS.prod_bkLnDelq}Options`,

      // Add new popup
      `${PERMISSIONS.glSet}Options`,
      `${PERMISSIONS.feeCalcDtl}Options`,
    ],
  },
};

export enum ENTITLEMENT_STATUS {
  noAccess = "no-access",
  readOnly = "read-only",
  readUpdate = "read-update",
  allAccess = "all-access",
}
export enum EntitlementPageType {
  ic = "ic",
  gl = "gl",
  tc = "tc",
  crt = "crt",
  pc_dep = "pc_dep",
  pc_loan = "pc_loan",
}
export type EntitlementType = {
  [key in EntitlementPageType]: ENTITLEMENT_STATUS;
};
export type FooterButtonType = "back" | "continue" | "finish";

export type ConfigFlowBtnType = FooterButtonType | "sidebar";

//transcode form flow name enums
export enum TRANSCODE_FLOW_NAME {
  rewardConfig = "rewardConfig",
  chargeOffTransCode = "chargeOffTransCode",
  limitSingleTransLimit = "limitSingleTransLimit",
  nsfTransCode = "nsfTransCode",
  termPenAdjust = "termPenAdjust",
  transCodeSpec = "transCodeSpec",
  feeServiceCharge = "feeServiceCharge",
  acctTypeLimit = "acctTypeLimit",
  finInstFdic = "finInstFdic",
  finOrgAccumLimit = "finOrgAccumLimit",
}

//transcode form flow route mapping
export const TRANSCODE_FLOW_NAME_ROUTE_MAP: Record<string, string> = {
  [TRANSCODE_FLOW_NAME.rewardConfig]: ROUTE_PATH.REWARD_CONFIGURATION_FORM,
  [TRANSCODE_FLOW_NAME.chargeOffTransCode]:
    ROUTE_PATH.CHARGE_OFF_TRANSACTIONAL_CODE,
  [TRANSCODE_FLOW_NAME.limitSingleTransLimit]:
    ROUTE_PATH.LIMIT_SINGLE_TRANSACTION_CONFIG,
  [TRANSCODE_FLOW_NAME.nsfTransCode]: ROUTE_PATH.NSF_TRANSACTIONAL_CODE,
  [TRANSCODE_FLOW_NAME.termPenAdjust]:
    ROUTE_PATH.TERM_DEPOSIT_PENALTIES_ADJUSTMENT,
  [TRANSCODE_FLOW_NAME.transCodeSpec]:
    ROUTE_PATH.TRANSACTION_TRANSACTIONAL_CODE,
  [TRANSCODE_FLOW_NAME.feeServiceCharge]: ROUTE_PATH.FEE_SERVICE_CHARGE_DETAILS,
  [TRANSCODE_FLOW_NAME.acctTypeLimit]: ROUTE_PATH.IC_ACCOUNT_TYPES_ADD_LIMIT,
  [TRANSCODE_FLOW_NAME.finInstFdic]:
    ROUTE_PATH.IC_BANK_PARAMS_FDIC_370_RELATED_FIELD,
  [TRANSCODE_FLOW_NAME.finOrgAccumLimit]:
    ROUTE_PATH.IC_FIN_ORG_PARTY_LIMIT_ACCUMULATED_TRANSACTION,
};

export const DEFAULT_PHONE_NO_PLACEHOLDER = "(222)222-2222";
export const DEFAULT_PHONE_NO_EXTENSION_PLACEHOLDER = "2222";

export const BUSINESS_TYPES = [
  "Financial Institution",
  "Fintech",
  "Merchant",
  "Consultancy",
  "Government",
  "Other",
];

export const FINANCIAL_PRODUCTS = [
  "Deposits",
  "Lending",
  "Wealth",
  "Cards",
  "Payments",
  "Crypto",
  "Other",
];

export const SANDBOX_OBJECTIVES = [
  "Apply Finxact Academy Training",
  "Build Financial Products",
  "Develop an Integration",
  "Explore Finxact Capabilities",
  "Test an Integration",
  "Other",
];
